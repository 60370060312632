$susy: (
        columns: 24,
        gutters: 30px/20.83px,
        math: fluid,
        output: float,
        gutter-position: split,
        global-box-sizing: border-box,
        use-custom: (rem: true)
);

/*$gallery_layout:(
    columns: 10,
    gutters: 24px/96px
);

$masonry_layout:(
    gutter-position: before
);*/


