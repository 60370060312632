

div.products {
    padding-bottom: 50px;

    > h3 {
        color: #94c347;
        font-size: em(20);
        text-transform: uppercase;

        img {
            margin-bottom: 4px;
        }
    }

    div.product-row {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > div {
            background-color: #fff;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            -o-flex-direction: column;
            flex-direction: column;
            align-items: center;
            border: 1px solid #e9e9e9;
            margin-right: 20px;
            margin-bottom: 20px;
            flex-basis: calc(25% - 22px);
            position: relative;

            @include breakpoint($md) {
                flex-basis: calc(33% - 22px);
                margin-right: 10px;
                margin-left: 10px;
            }

            @include breakpoint($sm) {
                flex-basis: calc(50% - 22px);
            }

            @include breakpoint($xs) {
                flex-basis: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            > a {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            > div.product-image {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 40px;
                max-height: 260px;
                flex-grow: 1;

                img {
                    max-height: 175px;
                }
            }

            > div.product-text {
                border-top: 1px solid #e9e9e9;
                padding: 24px;
                flex-grow: 1;

                h4 {
                    color: #5a2a82;
                    font-size: em(20);
                }

                p {
                    color: #5a2a82;
                    font-size: em(14);
                    border-collapse:collapse;
                }   
            }
        }
    }
}
