﻿.main-slider{
    .slick-dots {
        bottom: 20px;
        list-style: none;
        position: absolute;
        width: 100%;
        left: 0;
        text-align:center;
        display:none;
        @include breakpoint($md){
            padding-right:28px;
        }
        @include breakpoint($sm){
            display:block;
        }
        li{
            cursor: pointer;
            display: inline-block;
            margin: 0 5px;
            &:last-child{
                margin-right:0;
            }
            &.slick-active{
                button{
                    background:$white;
                    border-color:$white;
                }
            }
            button{
                font-size: 0;
                width:10px;
                height:10px;
                padding:0;
                background-color: transparent;
                border-radius:50%;
                border:1px solid $white;
                @include transition(all,0.3s,0s,1);
                &:hover{
                    background:transparent;
                }
            }
        }
    }

    .slick-arrow{
         @include breakpoint($sm){
            display:none !important;
        }
    }
}

.product-slider {
    padding-bottom: 20px;

    &--bottom-space {
        padding-bottom: 110px;

        @include breakpoint($sm) {
            padding-bottom: 90px;
        }

        @include breakpoint($xs) {
            padding-bottom: 60px;
        }
    }
    /* Slider */
    .slick-arrow {
        background: transparent;
        box-shadow: none;

        &.slick-next {
            right: -35px;

            @include breakpoint($lg) {
                right: 5px;
            }
        }

        &.slick-prev {
            left: -35px;

            @include breakpoint($lg) {
                left: 5px;
            }
        }
    }
}

.product-item {
    margin: 0 15px;
    border: 1px solid $gray-light-color;
    padding: 22px;
    position: relative;
    float: left;

    @include breakpoint($md) {
        padding: 15px;
    }

    @include breakpoint($xs) {
        margin: 0 3px;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        border: 4px solid #ebeae8;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        @include transition(all,0.35s,0s,1);
    }

    h2 {
        font-size: em(18);
        line-height: line-height(18, 22);
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 18px;
        height: 40px;
        overflow: hidden;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    &__body {
        padding-top: 22px;
    }

    &__price {
        font-size: em(18);
        color: $main-color;
        @extend .font-main;

        @include breakpoint($md) {
            font-size: em(16);
        }
    }

    &__old-price {
        text-decoration: line-through;
        margin-left: 5px;
        @extend .font-main;
        color: #848484;

        @include breakpoint($md) {
            font-size: em(12);
        }
    }

    &__image {
        height: 230px;
        position: relative;
        margin: 0;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            text-align: center;
            line-height: normal;
        }
    }
}

.thumbs-slider {
    &__body {
        float: right;
        width: 85.13%;
        padding-left: 3.3%;

        @include breakpoint($md) {
            width: 100%;
            padding-left: 0;
        }

        .slick-slider {
            border: 1px solid $gray-light-color;
        }
    }

    &__body-item {
        div {
            padding: 39px 0;

            img {
                margin: 0 auto;
            }
        }
    }

    &__nav {
        float: left;
        width: 14.87%;

        @include breakpoint($md) {
            width: 100%;
            padding-left: 0;
        }
    }

    &__nav-item {
        cursor: pointer;
        margin-bottom: 8px;

        div {
            @include transition(all,0.3s,0s,1);
            padding: 7px 0;
            border: 1px solid $gray-light-color;

            img {
                margin: 0 auto;
            }
        }

        &.slick-current {
            div {
                border-color: $main-color;
            }
        }
    }

    &__nav-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $white;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        will-change: opacity;
        text-align: center;
        @include transition(all,0.3s,0s,1);

        p {
            font-size: em(12);
            line-height: line-height(12, 17);
        }
    }

    &__nav-content {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @include transform(translateY(-50%));
        padding: 0 15.21%;
    }
}
