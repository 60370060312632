
section.strucna-podrska-i-saveti {
	padding-top: 50px;
	padding-bottom: 165px;

    @include breakpoint($lg2) {
        padding-bottom: 100px;
    }

    @include breakpoint($sm) {
        padding-bottom: 50px;
    }

	div.container {
		background-color: #fff;


		> h2 {
			color: #ff974a;
			font-size: em(40);
			padding-top: 30px;
			padding-bottom: 35px;
			text-align: center;

            @include breakpoint($sm) {
                font-size: em(32);
                padding-bottom: 20px;
            }
		}

		div#tabs {	
		padding-bottom: 50px;	
        
        @include breakpoint($xs) {
            padding-bottom: 15px;
        }	

			> ul {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: space-between;
				padding-left: 45px;
				padding-right: 45px;
				margin-bottom: 50px;

                @include breakpoint($md) {
                    padding-left: 0;
                    padding-right: 0;
                }

				&::after,
				&::before {
					display: none;
				}

				> li {
					flex: 0 1 calc(33% - 61px);
					height: 260px;
					background-color: #e9e9e9;
					position: relative;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					-webkit-flex-direction: column;
					-moz-flex-direction: column;
					-ms-flex-direction: column;
					-o-flex-direction: column;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					transition: all 0.15s ease;

                    @include breakpoint($md) {
                        flex: 0 1 calc(33% - 41px);
                    }

                    @include breakpoint($sm2) {
                        flex: 0 1 calc(33% - 31px);
                        height: 240px;
                    }

                    @include breakpoint($sm) {
                        flex: 0 1 calc(33% - 31px);
                        height: 200px;
                    }

                    @include breakpoint($xs2) {
                        flex: 0 1 calc(33% - 15px);
                        height: 170px;
                    }

                    @include breakpoint($xs) {
                        flex: 0 1 calc(33% - 10px);
                        height: 150px;
                    }

                    @include breakpoint($xss) {
                        height: 130px;
                        flex: 0 1 calc(33% - 5px);
                    }

					> a {
						position: absolute;
						top:0;
						left: 0;
						bottom: 0;
						right: 0;
					}

					> img.gray-image {

                        @include breakpoint($sm) {
                            transform: scale(0.7);                           
                        }

                        @include breakpoint($xs) {
                            transform: scale(0.5);                           
                        }
					}

                    > img.active-image {
                        display: none;

                        @include breakpoint($sm) {
                            transform: scale(0.7);
                        }

                        @include breakpoint($xs) {
                            transform: scale(0.5);                           
                        }
                    }

					> p {
						text-transform: uppercase;
						font-size: em(22);
						color: #808080;
						line-height: 1;
                        margin-top: auto;
                        padding-bottom: 20px;

                        @include breakpoint($sm) {
                            font-size: em(18);                            
                        }

                        @include breakpoint($xs) {
                            font-size: em(14); 
                            padding-bottom: 10px;                           
                        }
					}

                    > img {
                        margin-top: auto;
                    }

					&.ui-state-active {
						background-color: #94c347;

						> p {
							color: #fff;
						}
                        > img.gray-image {
						   display: none;
					    }

                        > img.active-image {
                            display: block;
                        }
					}

					&:nth-child(2) {

						&.ui-state-active {
							background-color: #94c347;

							> p {
								color: #fff;
							}
						}
					}
				}
			}

			div.accordion {

				> div.question {
					background-color: #e9e9e9;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-direction: row-reverse;
					margin-bottom: 5px;
					margin-left: -35px;
					margin-right: -35px;
					cursor: pointer;
					transition: all 0.2s;

                    @include breakpoint($lg) {
                        margin-left: 0;
                        margin-right: 0;
                    }

					&:hover {
						background-color: #ccc;
					}

					h3 {
						background-color: #5a2a82;
						color: #fff;
						margin-top: 0;
						margin-bottom: 0;
						padding: 15px 20px;
						margin-left: 70px;
						line-height: 1;
						width: max-content;
						font-size: em(20);

                        @include breakpoint($sm) {
                            margin-left: 20px;
                            padding-left: 15px;
                            padding-right: 15px;
                            font-size: em(16);
                        }

                        @include breakpoint($xs2) {
                            margin-left: 10px;
                            margin-right: 10px;
                            font-size: em(14);
                            max-width: 250px;
                        }

                        @include breakpoint($xss) {
                            max-width: 220px;
                        }
					}

					+ div {
						padding:30px 50px;
                        color: #808080;
                        font-size: 14px;
                        line-height: 20px;
                         -webkit-column-count: 2; /* Chrome, Safari, Opera */
                        -moz-column-count: 2; /* Firefox */
                        column-count: 2;
                        -webkit-column-gap: 50px; /* Chrome, Safari, Opera */
                        -moz-column-gap: 50px; /* Firefox */
                        column-gap: 50px;

                        @include breakpoint($md) {
                            padding: 30px 15px;                            
                        }

                        @include breakpoint($sm) {
                            -webkit-column-count: 1; /* Chrome, Safari, Opera */
                            -moz-column-count: 1; /* Firefox */
                            column-count: 1;  
                            padding: 20px 0;                          
                        }

						p {
                            margin-bottom: 10px;
							
                            @include breakpoint($sm) {
                                text-align: justify;
                            }
						}

                        ul {

                            li {
                                margin-bottom: 10px;
                            }
                        }
					}

					> span.ui-icon-triangle-1-s {
						background-image: url('../img/accordion-close.png');
						height: 22px;
						width: 22px;
						margin-right: 80px;

                        @include breakpoint($sm) {
                            margin-right: 20px;
                        }

                        @include breakpoint($xs2) {
                            margin-right: 10px;
                        }
					}
					> span.ui-icon-triangle-1-e {
						background-image: url('../img/accordion-open.png');
						height: 22px;
						width: 22px;
						margin-right: 80px;

                        @include breakpoint($sm) {
                            margin-right: 20px;
                        }

                        @include breakpoint($xs2) {
                            margin-right: 10px;
                        }
					}
				}

                div.ui-accordion-content {

                    table {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        tbody {

                            tr:first-child {
                                background-color: #5a2a82;

                                th {
                                    color: #fff;
                                    font-weight: normal;
                                    font-size: em(16);
                                    padding:14px 20px; 
                                }                       
                            }

                            tr {
                                background-color: #fff;

                                &:nth-child(even) {
                                    background-color: #e9e9e9;
                                }
                             }

                            td {
                                padding:14px 20px; 
                                 color: #808080;
                        
                                 @include breakpoint($sm) {
                                    font-size: em(12);
                                    padding:8px 12px; 
                                }

                                @include breakpoint($xs) {
                                    padding:4px 4px; 
                                    font-size: em(11);
                                }
                            }
                        }
                    }
                }
			}
		}
	}
}