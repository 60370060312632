﻿


@include font-face(MavenPro-Regular);
@include font-face(MavenPro-Bold);
@include font-face(MavenPro-Black);

@include font-face(mrdoor-icons);

.font-main{ font-family:'MavenPro-Regular';}
.font-main-700{ font-family:'MavenPro-Bold';} 
.font-main-700-B{ font-family:'MavenPro-Black';} 


.font-icons{ font-family:'mrdoor-icons';}


