
main.landing-page {
	width: 100%;
	height: 100vh;
	padding-top: 0;
	background-image: url('../img/bcg-splash-2.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	> div {
		height: 100vh;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> div.logo {
			margin-bottom: auto;
			background-color: #fff;
			width: 100%;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 200px;
			margin-top: 20px;

			@include breakpoint($md) {
				padding-left: 100px;
			}

			@include breakpoint($sm) {
				padding-left: 50px;
			}
		}

		> div.landing-buttons {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			margin-bottom: auto;

			@include breakpoint(1300px) {
				-webkit-flex-direction: column;
				-moz-flex-direction: column;
				-ms-flex-direction: column;
				-o-flex-direction: column;
				flex-direction: column;
			}

			> div {
				/*margin-left: 50px;*/
				margin-right: 50px;

				@include breakpoint(1300px) {
					margin-left: 0;
					margin-right: 0;
					margin-bottom: 100px;
				}

				@include breakpoint($sm) {
					margin-bottom: 70px;
				}

				p {
					color: #fff;
                    /*background-color: rgba(0,0,0,0.45);*/
					font-size: em(42);
					text-align: center;
					margin-bottom: 30px;

					@include breakpoint($sm) {
						margin-bottom: 15px;
					}
				}

				a {
					width: 550px;
					/*height: 50px;*/
					display: block;
					text-align: center;
					line-height: 50px;
					background-color: #8dc63f;
					transition: all 0.25s;
					font-size: em(16);
					font-weight: 700;

					@include breakpoint($sm) {
						width: 320px;
					}

					@include breakpoint($xs) {
						width: 280px;
					}

					&:hover {
						background-color: #2b982b;
					}
				}
			}
		}
	}
}