html, body {
    width: 100%;
    display: block;
}

html {
    font-weight: normal;
    font-family: $font-main;
    font-size: $browser-context+px;
    color: $sub-color;
    line-height: line-height($browser-context, $browser-line-height);
}

body{
    &.body-lock{
        @include breakpoint($md){
            overflow:hidden;
        }        
    }
}

a {
    text-decoration: none;
    color:$sub-color;
    outline: none !important;
    &:hover {
    }
}

.is-link {
    &:hover {
        text-decoration: underline;
    }
}

p{
    font-size:em(14);
    line-height:line-height(14, 20);
    margin:0;
}

input,
select, 
textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

ul{
    margin:0;
    padding:0;
    list-style:none;
}

main {
    background-color: #f0f3f0;
    padding-top: 97px;
}