﻿.banners {
    margin: 33px 0;
    &--bottom-space{
        margin-bottom:70px;
    }
    &--col-one {
        .banners__item {
            @include span(24);

            .media__wrapper {
                padding-bottom: 17.70%;
            }
        }
    }

    &--col-three {
        @include breakpoint($sm){
            margin:20px 0;
        }
        .banners__item {
            @include span(8);
            @include breakpoint($xs){
                 @include span(24);
                 margin-bottom:15px;
            }
            .media__wrapper {
                padding-bottom: 48.17%;
            }
        }
    }

    &--col-four {
        .banners__item {
            @include span(6);
            @include breakpoint($sm){
                 @include span(12);
                 margin-bottom:15px;
            }
            @include breakpoint($xs){
                 @include span(24);
                 margin-bottom:15px;
            }
        }

        .media__wrapper {
            padding-bottom: 57.14%;
        }
    }

    &__item {
        @include transition(all,0.3s,0s,1);

        &:hover {
            opacity: 0.9;
        }
    }
}
