
main {
    background-color: #f0f3f0;
    padding-top: 97px;

    section.slider {
        height: 550px;
        overflow: hidden;
        position: relative;

        @include breakpoint($extra-lg) {
            height: 450px;
        }

        @include breakpoint($md) {
            height: 400px;
        }

        @include breakpoint($sm2) {
            height: 320px;
        }

        @include breakpoint($sm) {
            height: 280px;
        }

        @include breakpoint($xs2) {
            height: 240px;
        }

        @include breakpoint($xs) {
            height: 200px;
        }

        .slick-slide img {

            @include breakpoint($xs) {
                min-height: 190px;
            }
        }

        .slick-next,
        .slick-prev,
        .slick-dots {
            display: none !important;
        }

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .text-content {
            position: absolute;
            top: 40%;
            color: #fff;
            background-color: rgba(90,42,130,0.78);
            padding: 40px 100px 50px 50px;

            @include breakpoint($md) {
                padding: 40px;
            }

            @include breakpoint($sm) {
                padding: 20px;
            }

            @include breakpoint($xss) {
                padding: 10px;
            }

            h2 {
                font-size: em(34);
                font-weight: 700;
                line-height: 48px;
                margin-bottom: 16px;

                @include breakpoint($md) {
                    font-size: em(24);
                    line-height: 1.5;
                }

                @include breakpoint($xss) {
                    font-size: em(20);
                    margin-bottom: 5px;
                    line-height: 1.3;
                }
            }

            p {
                font-size: em(16);
                line-height: 18px;

                @include breakpoint($md) {
                    font-size: em(14);
                }
            }
        }
    }

    section.options {
        background-color: #e9e9e9;
        height: 238px;

        @include breakpoint($md) {
            height: unset;
        }

        div.container {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: space-between;
            height: 100%;

            @include breakpoint($md) {
                flex-wrap: wrap;
            }

            @include breakpoint($xs2) {
                padding-left: 0;
                padding-right: 0;
            }

            > div {
                flex-basis: 25%;
                padding-bottom: 33px;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                -webkit-flex-direction: column;
                -moz-flex-direction: column;
                -ms-flex-direction: column;
                -o-flex-direction: column;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                background-color: transparent;
                background-position: center;
                position: relative;
                cursor: pointer;
                transition: all 0.45s ease;

                &:nth-child(2) {
                    @include breakpoint($xs) {
                        min-height: 50px !important;
                    }
                }


                @include breakpoint($md) {
                    flex-basis: 50%;
                    min-height: 250px;
                    background-color: #5a2a82;
                    justify-content: flex-start;
                    padding-bottom: 0;
                    padding-top: 30px;
                }

                @include breakpoint($xs2) {
                    min-height: 170px;
                    padding-top: 15px;
                }



                @include breakpoint($xs) {
                    background-image: unset !important;
                    flex-basis: 100%;
                    min-height: 50px;
                    padding-top: 10px;
                    margin-bottom: 3px;



                    &:hover {
                        min-height: 170px;

                        .options-text {
                            ul {
                                display: block;
                                padding-top: 10px;
                            }
                        }
                    }
                }

                div.options-text {
                    visibility: hidden;
                    position: absolute;
                    transition: all 0.45s ease;

                    @include breakpoint($md) {
                        visibility: visible;
                    }

                    p {
                        color: #fff;
                        font-size: em(22);
                        text-transform: uppercase;
                        text-align: center;
                        margin-bottom: 15px;
                        font-weight: 300;
                        line-height: 1;
                        font-family: $font-main;
                        margin-top: 10px;

                        @include breakpoint($md) {
                            font-size: em(18);
                        }

                        @include breakpoint($xs2) {
                            font-size: em(16);
                        }

                        a {
                            color: #fff;
                        }
                    }

                    ul {
                        padding-left: 20px;

                        @include breakpoint($xs) {
                            display: none;
                        }

                        li {
                            font-size: em(22);
                            margin-bottom: 10px;
                            line-height: 1;
                            font-weight: 300;
                            font-family: $font-main;



                            @include breakpoint($md) {
                                font-size: em(16);
                                color: #fff;
                                background: url('../img/white-arrow-right.png')no-repeat 0px 2px;
                                padding-left: 20px;
                            }

                            @include breakpoint($xs2) {
                                font-size: em(14);
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            a {
                                color: #fff;
                            }
                        }
                    }
                }

                > p {
                    color: #5a2a82;
                    text-transform: uppercase;
                    font-size: em(22);
                    font-weight: 300;
                    margin-top: 20px;

                    @include breakpoint($md) {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    background-image: url('../img/options-image-1-purple.png');
                    background-position: center;
                    background-repeat: no-repeat;

                    @include breakpoint($md) {
                        background-image: url('../img/options-image-1-white.png');
                    }

                    @include breakpoint($xs2) {
                        background-size: 30%;
                    }
                }

                &:nth-child(4) {
                    background-image: url('../img/options-image-2-purple.png');
                    background-position: center;
                    background-repeat: no-repeat;

                    @include breakpoint($md) {
                        background-image: url('../img/svetlo-siva-krava.png');
                    }

                    @include breakpoint($xs2) {
                        background-size: 30%;
                    }
                }

                &:nth-child(5) {
                    background-image: url('../img/options-image-3-purple.png');
                    background-position: center;
                    background-repeat: no-repeat;

                    @include breakpoint($md) {
                        background-image: url('../img/options-image-3-white.png');
                    }

                    @include breakpoint($xs2) {
                        background-size: 30%;
                    }
                }

                &:nth-child(2) {
                    background-image: url('../img/dog-food-pozitiv.png');
                    background-position: center;
                    background-repeat: no-repeat;

                    @include breakpoint($md) {
                        background-image: url('../img/dog-food-negativ.png');
                        /*background-size: 20%;*/
                    }

                    @include breakpoint($xs2) {
                        background-size: 30%;
                    }
                }

                &:last-child {
                    background-image: url('../img/options-image-4-purple.png');
                    background-position: center;
                    background-repeat: no-repeat;

                    @include breakpoint($md) {
                        background-image: url('../img/dodaci-hover.png');
                    }

                    @include breakpoint($xs2) {
                        background-size: 30%;
                    }
                }

                @media screen and (min-width: 1080px) {
                    &:hover {
                        background-color: #5a2a82;
                        transform: scale(1.1);
                        -webkit-transform-origin: 50%;
                        -moz-transform-origin: 50%;
                        -ms-transform-origin: 50%;
                        -o-transform-origin: 50%;
                        transform-origin: 50%;

                        div.options-text {
                            visibility: visible;
                            position: absolute;



                            p {
                                color: #fff;
                                font-size: em(22);
                                text-transform: uppercase;
                                text-align: center;
                                margin-bottom: 25px;
                            }

                            ul {
                                list-style-image: url('../img/white-arrow-right.png');


                                li {
                                    color: #fff;
                                    font-size: em(22);
                                    /*padding-left: 20px;*/
                                    margin-bottom: 10px;
                                }
                            }
                        }

                        > p {
                            display: none;
                        }

                        &:nth-child(3) {
                            background-image: url('../img/options-image-1-white.png');
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        &:nth-child(4) {
                            background-image: url('../img/svetlo-siva-krava.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            padding-bottom: 15px;
                        }

                        &:nth-child(5) {
                            background-image: url('../img/options-image-3-white.png');
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        &:nth-child(2) {
                            background-image: url('../img/dog-food-negativ.png');
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        &:last-child {
                            background-image: url('../img/dodaci-hover.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            padding-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    section.kompanijski-video {
        margin-top: 50px;

        > div.container {
            display: flex;
            justify-content: space-between;
            background-color: #e9e9e9;
            padding: 50px 100px 50px 30px;

            @include breakpoint($sm) {
                flex-direction: column;
                padding-left: 15px;
                padding-right: 15px;
            }

            > div:first-child {
                max-width: 70%;

                @include breakpoint($sm) {
                    margin-bottom: 40px;
                    text-align: center;
                    max-width: 100%;
                }
            }

            > div:last-child {

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img:last-child {
                        position: absolute;
                    }
                }

                .mfp-fade.mfp-bg {
                    opacity: 0;
                    -webkit-transition: all 0.15s ease-out;
                    -moz-transition: all 0.15s ease-out;
                    transition: all 0.15s ease-out;
                }

                .mfp-fade.mfp-bg.mfp-ready {
                    opacity: 0.8;
                }

                .mfp-fade.mfp-bg.mfp-removing {
                    opacity: 0;
                }

                .mfp-fade.mfp-wrap .mfp-content {
                    opacity: 0;
                    -webkit-transition: all 0.15s ease-out;
                    -moz-transition: all 0.15s ease-out;
                    transition: all 0.15s ease-out;
                }

                .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
                    opacity: 1;
                }

                .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
                    opacity: 0;
                }
            }
        }
    }

    section.novosti {

        div.container {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            -o-flex-direction: column;
            flex-direction: column;
            padding: 30px 30px 40px 30px;
            background-color: #ffffff;
            margin-bottom: 60px;
            margin-top: 55px;

            @include breakpoint($md) {
                padding: 15px;
            }

            div.main-news {
                background-image: url('../img/novosti-corner-logo.png');
                background-position: 99% 13px;
                background-repeat: no-repeat;
                padding: 35px;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;
                border: 1px solid #e1e1e1;
                margin-bottom: 35px;

                @include breakpoint($md) {
                    padding: 15px;
                }

                @include breakpoint($sm) {
                    flex-direction: column;
                    background-image: none;
                }

                img {
                    align-self: flex-start;
                    max-width: 700px;

                    @include breakpoint($md2) {
                        max-width: 500px;
                    }

                    @include breakpoint($sm2) {
                        max-width: 420px;
                    }

                    @include breakpoint($sm) {
                        max-width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 25px;
                    }
                }

                div.main-news-text {
                    padding-left: 35px;
                    position: relative;
                    flex-grow: 1;

                    @include breakpoint($sm) {
                        padding-left: 0;
                    }

                    h2 {
                        color: #ff974a;
                        text-transform: uppercase;
                        margin-top: 0;
                        font-size: em(40);
                        margin-bottom: 20px;
                        padding-right: 30px;

                        @include breakpoint($md2) {
                            padding-right: 40px;
                            font-size: em(36);
                        }

                        @include breakpoint($sm) {
                            font-size: em(30);
                            padding-right: 0;
                            text-align: center;
                        }

                        @include breakpoint($xs) {
                            font-size: em(26);
                        }
                    }

                    p:first-of-type {
                        color: #808080;
                        font-size: em(16);
                        margin-bottom: 40px;
                        font-weight: 500;
                    }

                    p {
                        font-size: em(14);
                        color: #808080;
                        margin-bottom: 20px;
                    }

                    > a {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        color: #5a2a82;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: em(12);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            div.thumb-news {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;

                @include breakpoint($sm2) {
                    flex-wrap: wrap;
                }

                > .emFix {

                    > em {

                        > a {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            color: #5a2a82;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: em(12);

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                > div {
                    flex-basis: calc(25% - 6px);
                    padding: 30px 10px 25px 20px;
                    border: 1px solid #e1e1e1;
                    position: relative;

                    @include breakpoint($sm2) {
                        padding: 15px;
                    }

                    @include breakpoint($sm) {
                        flex-basis: calc(50% - 6px);
                        margin-bottom: 20px;
                    }

                    @include breakpoint($xs) {
                        flex-basis: 100%;
                    }



                    h3 {
                        color: #ff974a;
                        font-size: em(20);
                        font-weight: 700;
                        margin-bottom: 14px;
                        min-height: 48px;

                        @include breakpoint($sm2) {
                            font-size: em(18);
                        }
                    }

                    > div {
                        display: -webkit-flex;
                        display: -moz-flex;
                        display: -ms-flex;
                        display: -o-flex;
                        display: flex;
                        align-items: flex-start;

                        @include breakpoint($md) {
                            flex-direction: column;
                        }

                        img {
                            max-width: 100px;

                            @include breakpoint($md) {
                                max-width: 80%;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            @include breakpoint($sm) {
                                max-width: 70%;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }

                        p {
                            color: #808080;
                            font-size: em(12);
                            margin-left: 12px;
                            line-height: 16px;
                            padding-bottom: 12px;

                            @include breakpoint($md) {
                                margin-left: 0;
                            }
                        }
                    }

                    > a {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        color: #5a2a82;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: em(12);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                > em {
                    flex-basis: calc(25% - 6px);
                    padding: 30px 10px 25px 20px;
                    border: 1px solid #e1e1e1;
                    position: relative;

                    @include breakpoint($sm2) {
                        padding: 15px;
                    }

                    @include breakpoint($sm) {
                        flex-basis: calc(50% - 6px);
                        margin-bottom: 20px;
                    }

                    @include breakpoint($xs) {
                        flex-basis: 100%;
                    }

                    .emFix {
                        flex-basis: calc(25% - 6px);
                        padding: 30px 10px 25px 20px;
                        position: relative;

                        @include breakpoint($sm2) {
                            padding: 15px;
                        }

                        @include breakpoint($sm) {
                            flex-basis: calc(50% - 6px);
                            margin-bottom: 20px;
                        }

                        @include breakpoint($xs) {
                            flex-basis: 100%;
                        }

                        > em {
                            flex-basis: unset !important;
                            padding: unset !important;
                            border: unset !important;

                            > a {
                                position: absolute;
                                bottom: 10px;
                                right: 10px;
                                color: #5a2a82;
                                text-transform: uppercase;
                                font-weight: 700;
                                font-size: em(12);

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        h3 {
                            color: #ff974a;
                            font-size: em(20);
                            font-weight: 700;
                            margin-bottom: 14px;
                            min-height: 48px;

                            @include breakpoint($sm2) {
                                font-size: em(18);
                            }
                        }

                        > div {
                            display: -webkit-flex;
                            display: -moz-flex;
                            display: -ms-flex;
                            display: -o-flex;
                            display: flex;
                            align-items: flex-start;

                            @include breakpoint($md) {
                                flex-direction: column;
                            }

                            img {
                                max-width: 100px;

                                @include breakpoint($md) {
                                    max-width: 80%;
                                    margin-left: auto;
                                    margin-right: auto;
                                }

                                @include breakpoint($sm) {
                                    max-width: 70%;
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                            }

                            p {
                                color: #808080;
                                font-size: em(12);
                                margin-left: 12px;
                                line-height: 16px;
                                padding-bottom: 12px;

                                @include breakpoint($md) {
                                    margin-left: 0;
                                }
                            }
                        }

                        > a {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            color: #5a2a82;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: em(12);

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    section.deo-tima {
        background-color: #e9e9e9;

        div.container {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 40px;

            @include breakpoint($md) {
                flex-direction: column;
                align-items: center;
            }

            > div {
                padding-bottom: 57px;

                p {
                    color: #5a2a82;
                    font-size: em(17);

                    &.title {
                        font-weight: 300;
                        font-size: em(24);
                        text-transform: uppercase;
                        margin-bottom: 10px;

                        @include breakpoint($sm) {
                            margin-bottom: 20px;
                        }
                    }

                    &:nth-child(2) {
                        margin-bottom: 25px;
                    }

                    a {
                        font-weight: bold;
                        color: #5a2a82;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                ul {
                    list-style-type: disc;
                    padding-left: 40px;
                    margin-bottom: 20px;

                    li {
                        font-size: em(17);
                        margin-bottom: 6px;
                        color: #5a2a82;

                        a {
                            color: #5a2a82;
                            transition: all 0.25s;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    section.o-nama {
        background-color: white;

        > div.container:first-child {
            background-image: url('../img/o-nama-BG.png');
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 142px;

            @include breakpoint($md) {
                margin-bottom: 70px;
                padding-top: 50px;
            }

            @include breakpoint($xs) {
                margin-bottom: 50px;
                padding-top: 30px;
            }

            @include breakpoint($xs2) {
                background-position: 0 15%;
            }

            > div:first-child {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;
                margin-top: 60px;
                padding-top: 70px;
                padding-left: 60px;
                padding-right: 40px;
                margin-bottom: 130px;

                @include breakpoint($md) {
                    margin-bottom: 70px;
                    padding-bottom: 0;
                }

                @include breakpoint($sm) {
                    margin-bottom: 40px;
                }

                @include breakpoint($xs2) {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 30px;
                }

                > div {
                    max-width: 600px;

                    @include breakpoint($md) {
                        max-width: unset;
                    }

                    h3 {
                        font-size: em(22);
                        color: #fff;
                        text-transform: uppercase;
                        margin-bottom: 42px;

                        @include breakpoint($xs) {
                            margin-bottom: 20px;
                        }
                    }

                    p {
                        font-size: em(16);
                        color: #fcfcfc;

                        @include breakpoint($xs) {
                            font-size: em(14);
                            text-align: justify;
                        }
                    }
                }

                > img {

                    @include breakpoint($md) {
                        display: none;
                    }
                }
            }

            > div:last-child {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-around;

                @include breakpoint($lg) {
                    justify-content: space-between;
                }

                @include breakpoint($md) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                @include breakpoint($xs2) {
                    flex-direction: column;
                }

                > div {

                    @include breakpoint($md) {
                        flex: 0 1 50%;
                        margin-bottom: 25px;
                        display: flex;
                        justify-content: center;
                    }


                    > div.image {
                        background-color: #fff;
                        border: 1px solid #cccccc;
                        display: -webkit-flex;
                        display: -moz-flex;
                        display: -ms-flex;
                        display: -o-flex;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding: 26px 15px 20px;
                        width: 268px;
                        height: 144px;

                        @include breakpoint($lg) {
                            padding: 15px;
                            width: 245px;
                        }

                        p {
                            font-size: em(14);
                            color: #000;

                            span {
                                font-size: 30px;
                                font-family: $font-main-700-B;
                                color: #5a2a82;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }

        > div.container-fluid {
            padding: 60px 0 60px;
            background-image: url('../img/o-nama-saznaj-vise-BG.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            > div.container {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include breakpoint($sm) {
                    flex-direction: column;
                }

                > div {

                    @include breakpoint($sm) {
                        margin-bottom: 40px;
                        text-align: center;
                    }

                    p:first-child {
                        font-size: em(24);
                        color: #fff;
                        font-weight: 700;
                    }

                    p:last-child {
                        font-size: em(16);
                        color: #fff;
                        font-weight: 300;
                    }
                }

                > a {
                    color: #fff;
                    width: 180px;
                    height: 44px;
                    line-height: 44px;
                }
            }
        }
    }
}
