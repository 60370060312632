
section.product-dodaci-info {
	margin-bottom: 100px;

	div.container {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> div {
			flex: 0 1 calc(50% - 25px);
			margin-bottom: 25px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			-webkit-flex-direction: column;
			-moz-flex-direction: column;
			-ms-flex-direction: column;
			-o-flex-direction: column;
			flex-direction: column;

			> h4 {
				background-color: #e9e9e9;
				text-transform: uppercase;
				font-weight: bold;
				color: #5a2a82;
				font-size: em(16);
				padding: 15px 30px;
				margin-bottom: 0;
			}
			> div {
				border: 1px solid #e9e9e9;
				background-color: #fff;
				padding: 30px;
				padding-bottom: 50px;
				color: #808080;
				font-size: em(16);
				flex-grow: 1;

				p {
					margin-bottom: 10px;
				}

				ul {

					li {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}