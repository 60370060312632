﻿
section.error-page {
    padding-bottom: 160px;
    padding-top: 180px;

    @include breakpoint($lg2) {
        padding-bottom: 120px;
        padding-top: 120px;
    }

    div.text-part {

        h1 {
            font-size: em(65);
        }

        a {
            margin-top: 40px;
            display: inline-block;
            text-decoration: underline;
            font-size: em(22);

            &:hover {
                text-decoration: none;
            }
        }
    }
}