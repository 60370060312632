﻿
footer {

    .footer__info {
        background-color: #5a2a82;

           div.container {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: space-between;
                padding: 50px 0;

                @include breakpoint($lg) {
                    padding: 40px 15px;
                }
                @include breakpoint($md) {
                    flex-wrap: wrap;
                }

                div {
                    @include breakpoint($md) {
                        flex:0 1 50%;
                        padding-left: 50px;
                        margin-bottom: 50px;
                    }
                    @include breakpoint($xs) {
                        flex: 0 1 100%;
                        text-align: center;
                        padding-left: 0;
                    }

                    &:last-child {

                        @include breakpoint($md) {
                            margin-bottom: 0;
                        }
                    }

                    ul {

                        li:first-child {
                            color: #fff;
                            margin-bottom: 20px;
                            text-transform: uppercase;

                            @include breakpoint($md) {
                                margin-bottom: 12px;
                            }
                        }
                        li {
                            color:  #fff;
                            line-height: 1.3;
                            margin-bottom: 10px;
                            max-width: 300px;
                            letter-spacing: 1px;
                            font-family: $font-main;

                            @include breakpoint($xs) {
                                max-width: unset;
                            }

                            a {
                                color: inherit;
                                transition: all 0.25s;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                &:after {
                    display: none;
                }
            }
    }

 

    .copyright {
        background-color: #5a2a82;
        padding: 15px 0;
        text-align: center;
        color: #fff;

        a {
            color: #FFF;
        }
    }


}