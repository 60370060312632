
section.strucna-podrska-i-razvoj {
   padding-top: 100px;

   @include breakpoint($sm) {
       padding-top: 50px;
   }

     div.container {

        > div:first-child {
          background-color: #94c347;
          color: #fff;
          padding: 45px;
          margin-bottom: 70px;

          @include breakpoint($xs) {
                padding: 20px;
            }

            h3 {
              font-size: em(22);
              text-transform: uppercase;
            }

            > div {
              display: -webkit-flex;
              display: -moz-flex;
              display: -ms-flex;
              display: -o-flex;
              display: flex;

              @include breakpoint($xs) {
                  flex-direction: column;
              }

              p {
                font-size: em(14);
                padding-right: 20px;

                @include breakpoint($xs) {
                      margin-bottom: 20px;
                      padding-right: 0;
                  }
              }

              ul {
                  margin-left: 40px;
                  list-style-type: disc;

                  li {
                      margin-bottom:6px;
                  }
              }
            }

        }

        div.tip-podrske {
          background-color: #e9e9e9;
          margin-bottom: 70px;
          padding-right: 50px;
          padding-bottom: 50px;

          @include breakpoint($sm2) {
              padding-right: 0;
          }

          h4 {
            color: #fff;
            background-color: #5a2a82;
            height: 50px;
            width: 205px;
            text-align: center;
            line-height: 50px;
            font-size: em(20);
            position: relative;
            bottom: 8px;
            left: 17px;
          }

          > div {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;

            @include breakpoint($sm2) {
                flex-direction: column;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
            }

            p {
              color: #808080;
              font-size: em(14);
              padding-left: 40px;
              padding-right: 50px;
              margin-bottom: 10px;

                  @include breakpoint($sm2) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }

            ul {
                padding-left: 56px;
                margin-top: 15px;
                list-style-type: disc;

                @include breakpoint($sm2) {
                    padding-left: 20px;
                }

                li {
                    color: #808080;
                    font-size: em(14);
                    margin-bottom: 6px;
                }
            }

            img {
                max-width: 100%;
                height: auto;
                display: block;
                margin-left: auto;

                @include breakpoint($sm2) {
                    margin-top: 30px;
                    align-self: center;
                }
            }
          }
        }
     }
}