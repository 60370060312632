﻿
.custom-check {
    display: block;
    position: relative;
    float: left;
    width: 100%;

    &.error {
        .error-terms {
            color: $main-color;
            display: block;
            margin-top: 15px;
            @extend .font-main;
        }
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    &--border-wrap {
        border: 2px solid #ebeae8;
        padding: 15px 12px;
    }

    input[type="checkbox"], input[type="radio"] {
        display: none;

        & + label {
            color: $gray-color;
            cursor: pointer;
            font-size: em(14);
            padding-left: 22px;
            margin: 0;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            position: relative;
            @extend .font-main;
            @include transition(all,0.2s,0s,2);

            &:after {
                border: 1px solid $gray-color;
                background-color: $white;
                content: "";
                height: 10px;
                width: 10px;
                position: absolute;
                left: 0;
                top: 50%;
                @include transform(translateY(-50%));
            }
        }

        &:checked {
            & + label {
                color: $black;

                &:after {
                    border-color: $black;
                    background-color: $black;
                }
            }
        }
    }

    input[type="radio"] {
        & + label {
            &:after {
                border-radius: 50%;
            }

            &:before {
                border-radius: 50%;
                background-color: $main-color;
                content: "";
                height: 6px;
                width: 6px;
                position: absolute;
                left: 2px;
                top: 50%;
                @include transform(translateY(-50%));
                z-index: 1;
                opacity: 0;
                @include transition(all,0.3s,0s,2);
                will-change: transform;
            }
        }

        &:checked {
            & + label {
                &:after {
                    border-color: $gray-color;
                    background-color: $white;
                }

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}
