
section.o-nama-page {

    div.o-nama-top {
		
		div.container:first-child {
    		background-image: url('../img/o-nama-BG.png');
		    background-repeat: no-repeat;
		    background-position: center;
		    margin-bottom: 74px;
		    padding-bottom: 30px;

            @include breakpoint($md) {
                margin-bottom: 70px;
                padding-bottom: 0;
            }
            @include breakpoint($xs2) {
                background-position: 0 15%;
            }
		    
		    > div:first-child {
		    	display: -webkit-flex;
			    display: -moz-flex;
			    display: -ms-flex;
			    display: -o-flex;
			    display: flex;
			    justify-content: space-between;
			    margin-top: 60px;
			    padding-top: 70px;
			    padding-left: 60px;
			    padding-right: 40px;
			    margin-bottom: 70px;

                @include breakpoint($md) {
                    margin-bottom: 50px;
                    margin-top: 0;
                    padding-bottom: 0;
                    padding-top: 90px;
                }
                @include breakpoint($sm) {
                    margin-bottom: 40px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @include breakpoint($xs2) {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 100px;
                }
                @include breakpoint($xs) {
                    padding-top: 110px;
                }

			    > div {
                    max-width: 600px;

                    @include breakpoint($md) {
                        max-width: unset;
                    }

			    	h3 {
			    		font-size: em(22);
			    		color: #fff;
			    		text-transform: uppercase;
			    		margin-bottom: 42px;

                        @include breakpoint($md) {
                            margin-bottom: 20px;
                        }

                        @include breakpoint($xs) {
                            margin-bottom: 10px;
                        }
			    	}

			    	p {
			    		font-size: em(16);
			    		color: #fcfcfc;

                        @include breakpoint($xs) {
                            font-size: em(14);
                            text-align: justify;
                            line-height: 1.2;
                        }
			    	}
			    }

                > img {

                    @include breakpoint($md) {
                        display: none;
                    }
                }
		    }

		    > div:last-child {
		    	display: -webkit-flex;
		    	display: -moz-flex;
		    	display: -ms-flex;
		    	display: -o-flex;
		    	display: flex;
		    	justify-content: space-around;

                @include breakpoint($lg) {
                    justify-content: space-between;
                }
                @include breakpoint($md) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                @include breakpoint($xs2) {
                    flex-direction: column;
                }

		    	> div {

                    @include breakpoint($md) {
                        flex: 0 1 50%;
                        margin-bottom: 25px;
                        display: flex;
                        justify-content: center;
                    }

		    		> div.image {
		    			background-color: #fff;
		    			border: 1px solid #cccccc;
		    			display: -webkit-flex;
		    			display: -moz-flex;
		    			display: -ms-flex;
		    			display: -o-flex;
		    			display: flex;
		    			justify-content: space-between;
		    			align-items: flex-start;
                        padding: 26px 15px 20px;
		    			width: 268px;
		    			height: 144px;

                        @include breakpoint($lg) {
                            padding: 15px;
                            width: 245px;
                        }
                        
		    		    p {
		    			    font-size: em(14);
		    			    color: #000;

		    			    span {
		    				    font-size: 30px;
                                font-family: $font-main-700-B;
                                color: #5a2a82;
                                line-height: 1;
		    			    }
		    		    }
		    		}
		    	}
		    }
    	}
    }

   	div.politika-kvaliteta {
   		margin-bottom: 60px;

   		div.container {
   			background-color: #fff;
   			padding: 50px 35px;

               @include breakpoint($xs2) {
                   padding: 30px 15px;
               }

   			> h3 {
   				color: #ff974a;
   				font-size: em(22);
   				text-transform: uppercase;
   				margin-bottom: 30px;
   				font-weight: 500;
                text-align:center;
   			}

   			> div {
   				
                ul {

                    li {
                        margin-bottom: 15px;
                    }
                }
   			}

            div.sertifikati {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;

                @include breakpoint($xs) {
                    flex-direction: column;
                }

                img {

                    @include breakpoint($xs) {
                        margin-bottom: 30px;
                    }
                }
            }

   			> ul {
   				display: -webkit-flex;
   				display: -moz-flex;
   				display: -ms-flex;
   				display: -o-flex;
   				display: flex;
   				flex-wrap: wrap;
   				justify-content: space-between;

   				li {
   					flex-basis: 47%;
   					margin-bottom: 25px;

                       @include breakpoint($xs2) {
                           flex-basis: 100%;
                       }

   					h4 {
   						color: $purple-font-color;
   						font-size: em(18);
   						margin-bottom: 12px;
   						font-weight: 700;
   					}
   					p {
   						color: $gray-font-color;
   						font-size: em(14);
   						line-height: 20px;

   						> a {
   							color: #94c347; 
   						}
   					}
   				}
   			}
   		}
   	}	

   	div.nas-tim {
   		margin-bottom: 30px;

   		div.container {
   			background-color: #94c347;
   			padding: 50px 35px;
   			color: #fff;

            @include breakpoint($sm) {
                padding: 30px 15px;
            }

   			> div {
   				display: -webkit-flex;
   				display: -moz-flex;
   				display: -ms-flex;
   				display: -o-flex;
   				display: flex;
   				justify-content: space-between;

   				> p {
   					flex-basis: 47%;

                    @include breakpoint($sm) {
                        flex-basis: 100%;
                    }
   				}
   			}
   		}
   	}	

   	div.trzista {
   		background-color: #fff;
   		margin-bottom: 40px;

   		div.container {
   			display: -webkit-flex;
   			display: -moz-flex;
   			display: -ms-flex;
   			display: -o-flex;
   			display: flex;
   			-webkit-flex-direction: column;
   			-moz-flex-direction: column;
   			-ms-flex-direction: column;
   			-o-flex-direction: column;
   			flex-direction: column;
   			justify-content: center;
   			align-items: center;

   			h3 {
   				color: $orange-font-color;
   				text-transform: uppercase;
   				font-size: em(30);
   				margin-top: 100px;
   				margin-bottom: 70px;

                @include breakpoint($md) {
                    margin-top: 60px;
                    margin-bottom: 50px;
                }

                @include breakpoint($xs) {
                    font-size: em(24);
                    margin-bottom: 30px;
                    margin-top: 40px;
                }
   			}

   			img {
   				margin-bottom: 60px;
                  margin-top: 40px;
   			}

            div {

                p {
                    -webkit-column-count: 3; /* Chrome, Safari, Opera */
                    -moz-column-count: 3; /* Firefox */
                    column-count: 3;
                    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
                    -moz-column-gap: 40px; /* Firefox */
                    column-gap: 40px;

                    @include breakpoint($md) {
                        -webkit-column-count: 2; /* Chrome, Safari, Opera */
                        -moz-column-count: 2; /* Firefox */
                        column-count: 2;
                    }

                    @include breakpoint($xs2) {
                        -webkit-column-count: 1; /* Chrome, Safari, Opera */
                        -moz-column-count: 1; /* Firefox */
                        column-count: 1;
                        text-align: justify;
                    }
                }
            }
   		}
   	}
}