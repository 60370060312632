
main {

	section.top-info {
		background-color: #e9e9e9;		
        padding-top: 100px;

        @include breakpoint($md) {
            padding-top: 50px;
        }

		div.container {			
			background-position: 100% bottom;
			background-repeat: no-repeat;
			padding-bottom: 70px;
		    padding-top: 30px;
            min-height: 200px;

            @include breakpoint($md) {
                padding-top: 0;
            }

            h3 {
                color: #5a2a82;
                text-transform: uppercase;
                font-size: 24px;
                font-family: $font-main-700;
            }
			
			> div {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
                width: 50%;
                font-size: 14px;
                color: #808080;
                line-height: 20px;

                @include breakpoint($sm) {
                    width: 100%;
                }               

				p {					
					padding-right: 30px;

                    @include breakpoint($sm) {
                        padding-right: 0;
                        text-align: justify;
                    }
				}
			}
		}
	}

	section.category-products {
		padding-top: 50px;

		/*  stilovi za proizvode se nalaze u fajlu scss/components/product-row.scss  */
	}
}