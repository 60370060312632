@charset "utf-8";
//#######################################
//## MIXINS #############################
//#######################################
@import "helpers/mixins/responsive";
@import "helpers/mixins/background";
@import "helpers/mixins/box";
@import "helpers/mixins/fonts";
@import "helpers/mixins/animation";
@import "helpers/mixins/triangle";
@import "helpers/mixins/placeholder";
@import "helpers/mixins/itemblock";
@import "helpers/mixins/z-index";
@import "helpers/mixins/transform";
@import "helpers/mixins/clearfix";
//#######################################
//## VARIABLES ##########################
//#######################################
@import "helpers/variables/routes";
@import "helpers/variables/colors";
@import "helpers/variables/fonts";
@import "helpers/variables/transition";
@import "helpers/variables/z-index";
@import "helpers/variables/responsive";
//#######################################
//## FUNCTIONS ##########################
//#######################################
@import "helpers/functions/fonts";
@import "helpers/functions/transition-type";
//#######################################
//## FRAMEWORKS #########################
//####################################### 
@import "../../bower_components/susy/sass/susy";
@import "frameworks/sussy/settings";
//@import "frameworks/zkeleton/zkeleton";
//#######################################
//## BASE ###############################
//#######################################
@import "base/reset";
@import "base/html";
@import "base/fonts";
//#######################################
//## COMPONENTS #########################
//#######################################
@import "components/buttons";
@import "components/helpers";
@import "components/header";
@import "components/banners";
@import "components/products-sliders";
@import "components/product-row";
@import "components/footer";
@import "components/checkbox";
@import "components/single-products";
//#######################################
//## PLUGINS ############################
//#######################################
@import "plugins/slick";
@import "plugins/jquery.jscrollpane";
@import "plugins/chosen";
@import "plugins/jquery-ui";
@import "plugins/magnific-popup";
@import "plugins/jquery.fancybox";
//#######################################
//## PAGES ##############################
//#######################################
@import "pages/category";
@import "pages/product-dodaci";
@import "pages/product-koncentrati";
@import "pages/strucna-podrska-i-razvoj";
@import "pages/strucna-podrska-i-saveti";
@import "pages/otkupne-cene";
@import "pages/o-nama";
@import "pages/mediji";
@import "pages/galerija";
@import "pages/zaposleni";
@import "pages/novosti";
@import "pages/single-novost";
@import "pages/kontakt";
@import "pages/index";
@import "pages/landing-page";
@import "pages/404";




//Cookie Info

.cookie-info {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999999;
    display:none;
    &.show {
        display: flex;
        background-color: black;
        /*-webkit-box-shadow: 0px -4px 4px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -4px 4px 0px rgba(0,0,0,0.75);
        box-shadow: 0px -4px 4px 0px rgba(0,0,0,0.75);*/
    }

    .btn {
        border: 1px solid black;
    }

    .btn {
        background-color: white;

        a:hover {
            color: black;
        }
    }


    .cookie-info-content {
        position: relative;
        background-color: black;
        margin: 0 auto;
        max-width: 100%;
        padding: 20px 10px;
        text-align: center;
        display: flex;
        border-top: 2px solid black;
        justify-content:space-around;
        .desno {
            display: flex;
            justify-content: center;
            align-items:center;
            margin-left:30px;
            @include breakpoint($xs2) {
                padding-top: 10px;
            }

            > div {
                > a {
                    white-space: nowrap;
                    padding: 10px;
                    color: black;
                }

                > a:hover {
                    border-color: black;
                    transform: scale(1.1);
                }
            }

            .link1 {
                padding: 0px 20px 0px 20px;
            }
        }

        p {
            line-height: 20px;
            color: #fff;
            font-weight: 400;
            margin: 0;

            .more-info {
                margin-left: 20px;
            }

            .cookie-info-close,
            .more-info {
                background: #fff;
                border-color: #fff;
                color: #000;
                margin-left: 15px;
                padding: 1px 10px;
                text-transform: none;

                &:hover {
                    border-color: #fff;
                    background: #000;
                    color: #fff;
                }
            }
        }

        @include breakpoint($xs2) {
            display: unset;
        }
    }
}

/*Legal*/
.legal {
    padding-top: 100px;

    div.tip-podrske {
        background-color: #e9e9e9;
        margin-bottom: 70px;
        padding-right: 50px;
        padding-bottom: 50px;

        @include breakpoint($sm2) {
            padding-right: 0;
        }

        h4 {
            color: #fff;
            background-color: #5a2a82;
            height: 50px;
            width: max-content;
            text-align: center;
            line-height: 50px;
            font-size: em(22);
            position: relative;
            bottom: 8px;
            left: 17px;
        }

        > div {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;

            @include breakpoint($sm2) {
                flex-direction: column;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
            }



            ul {
                padding-left: 56px;
                margin-top: 15px;
                list-style-type: disc;

                @include breakpoint($sm2) {
                    padding-left: 20px;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }

                    a {
                        color: #808080;
                        font-size: em(16);

                        &:hover {
                            transform: scale(1.05);
                            color: #5a2a82;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}