
section.mediji {
	margin-top: 60px;
	padding-bottom: 150px;

    @include breakpoint($lg2) {
        padding-bottom: 100px;
    }

    @include breakpoint($xs) {
        padding-bottom: 50px;
    }
	
	div.container {
		background-color: #fff;
		padding: 50px 35px;

		> h3 {
			color: $orange-font-color;
			font-size: em(22);
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		> div {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			margin-bottom: 100px;
            display: none;

			> p {
				flex-basis: 47%;
				color: $gray-font-color;
				font-size: em(14)
			}
		}

		> ul {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			
			> li {
				flex-basis: calc(25% - 30px);
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				-webkit-flex-direction: column;
				-moz-flex-direction: column;
				-ms-flex-direction: column;
				-o-flex-direction: column;
				flex-direction: column;
				border: 1px solid #cccccc;
				margin-bottom: 30px;
				margin-right: 15px;
				margin-left: 15px;
				position: relative;

                @include breakpoint($md) {
                    flex-basis: calc(33% - 30px);
                }

                @include breakpoint($sm) {
                    flex-basis: calc(50% - 30px);
                }

                @include breakpoint($xs) {
                    flex-basis: calc(100%);
                    margin-left: 0;
                    margin-right: 0;
                }

				> div {
					padding: 18px 12px;

					> p {
						font-size: em(11);
						color: $orange-font-color;
						margin-bottom: 10px;
					}
					> h4 {
						font-size: em(16);
						color: $purple-font-color;
						margin-bottom: 0px;
						padding-bottom: 20px;
					}
				}

				> a {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}
}



div#video-popup {
	  position: relative;
	  background: #FFF;
	  padding: 40px;
	  width: 380px;
	  margin: 20px auto;
	  display: -webkit-flex;
	  display: -moz-flex;
	  display: -ms-flex;
	  display: -o-flex;
	  display: flex;
	  -webkit-flex-direction: column;
	  -moz-flex-direction: column;
	  -ms-flex-direction: column;
	  -o-flex-direction: column;
	  flex-direction: column;
	  justify-content: space-between;
	  align-items: center;
        
      button.mfp-close {
          position: absolute;
          top: 0;
          right: 10px;
          font-size: 40px;
      }
}

.mfp-content {

    iframe {

        @include breakpoint($md) {
            height: 400px;
        }

        @include breakpoint($xs) {
            height: 350px;
        }

        @include breakpoint($xss) {
            height: 300px;
        }
    }
}