.main__wrapper{
    padding-top: 156px;
    @include transition(all,0.35s,0s,1);

    @include breakpoint($md){
        padding-top:68px;
    }

    &--no-padding {
        padding-top: 0;
    }

    &--big-space {
        padding-top: 112px;

        @include breakpoint($lg) {
            padding-top: 102px;
        }

        @include breakpoint($md) {
            padding-top: 95px;
        }
    }
}

.overflow-hidden{
    overflow:hidden;
}

.block{
    display: block;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.clear {
    @include clearfix;
}

.container{
    @include container(1200px);
    @include breakpoint($lg){
        padding:0 15px;
    }
     &:after{
        display:none;
    } 
    &--small{
        @include container(802px);
    }   
    &--border-top{
        border-top:1px solid #d9d9d9;
    }     
    &.no-padding-on-mobile {
        @include breakpoint($xs){
            padding:0;
        }
    }
}

.vertical-aligned{
    font-size: 0;
    white-space: nowrap;
    &__column{
        display: inline-block;
        float: none !important;
        vertical-align: middle;
        white-space: normal;
        font-size:16px;
    }
}

.hide-on-mobile {
    @include breakpoint($sm){
        display: none !important;
    }   
}

.media__wrapper{
    position:relative;
    overflow:hidden;
    margin:0;
    img{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}

.section{
    &__flex-wrapper{
        display:flex;
        flex-wrap:wrap;
    }
}

h1{
    font-size:em(60px);
    line-height:line-height(60, 66);
}

.inner{
    @include full();
    @include susy-clearfix;
}

.input-field {
    position: relative;
    padding-bottom: 18px;
    float: left;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    &--left {
        width: 50%;
        float: left;
        margin-right: -2px;

        @include breakpoint($xs) {
            width: 100%;
            margin-right: 0;
        }
    }

    &--right {
        width: 50%;
        float: left;

        @include breakpoint($xs) {
            width: 100%;
            margin-left: 0;
        }

        input[type="password"] {
            &:focus {
                border-left-color: #ebeae8 !important;
            }
        }
    }

    &.no-label {
        padding-top: 19px;
    }

    &--last {
        margin-bottom: 38px;
    }

    input[type=text], textarea, input[type=password], input[type=email] {
        border: 2px solid #ebeae8;
        color: #5b5b5b;
        font-size: em(16);
        padding: 0 15px;
        height: 50px;
        width: 100%;
        background-color: $white;
        @include transition(all,0.15s,0s,2);
        @extend .font-main;

        &:focus {
            outline: none;
            border-color: #cbcac8;
        }
    }

    input[type=text], input[type=password], input[type=email] {
        @include placeholder {
            opacity: 1;
            color: #8c8c8c;
        }
    }

    textarea {
        height: 112px;
        display: table;
        line-height: line-height(15, 18);
        padding: 22px 15px;

        @include placeholder {
            opacity: 1;
            color: $white;
        }
    }

    .chosen-container {
        float: left;
        width: 100% !important;
        border: 2px solid $gray-light-color;


        .chosen-drop {
            margin-top: -7px;
            letter-spacing: normal;
            font-size: 16px;
            width: calc(100% + 2px);
            left: -1px;
            transform: translateY(7px) !important;

            .chosen-results {
                li {
                    @extend .font-main;
                }
            }
        }
    }

    .chosen-container-single {
        .chosen-single {
            padding: 16px;

            span {
                @extend .font-main;
                font-weight: 300;
                font-size: 16px;
                color: $gray-light-color;
                letter-spacing: normal;
                line-height: 16px;
            }

            div {
                b {
                    bottom: 20px;
                }
            }
        }
    }

    .validation,
    .error-alert {
        position: absolute;
        bottom: 5px;
        right: 0;
        font-size: em(12);
        color: #ef0000;
        display: none;
        @extend .font-main;
        @include transition(all,0.2s,0s,2);

        &.second {
            bottom: -10px;
        }
    }

    label {
        color: #4b4949;
        font-size: em(14);
        @extend .font-main;
        margin-bottom: 5px;
        display: table;
        position: relative;

        &:after {
            position: absolute;
            left: 100%;
            top: 0;
            content: '*';
            color: $main-color;
            display: none;
        }
    }

    &--required {
        label {
            &:after {
                display: block;
            }
        }
    }

    &.is-error {
        .validation{
            opacity: 1;
            visibility: visible;
        }
    }

    .form-cta {
        background-color: #31cc63;
        height: 44px;
        line-height: 42px;
        font-size: em(12);
        border: 0;
        width: 186px;
        margin: 0 auto;
        display: block;

        &:hover {
            background-color: #41dc73;
        }
    }

    &.error {
        input {
            border-color: #ef0000;
        }
    }
}

.main-title{
    text-align:center;
    padding:50px 0;
    @include breakpoint($xs){
        padding: 35px 0 40px;
    }
    &--top-20{
        padding-top: 20px;
    }

    h1, h2{
        font-size:em(34);
        line-height:line-height(30, 34);
        text-transform:uppercase;
        display:table;
        position:relative;
        margin:0 auto;
        padding:0 65px;
        @include breakpoint($xs){
            font-size:em(24);
            line-height:line-height(24, 28);
            padding:0 30px;
        }
        &:after{
            content:'';
            position:absolute;
            top:50%;
            left:0;
            right:0;
            border-top:1px solid $gray-light-color;
            z-index:-1;
        }
        span{
            padding:0 25px;
            background-color:$white;
            display:block;
            @include breakpoint($xs){
                padding:0 5px;
            }
        }
    }
}

