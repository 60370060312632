﻿
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    will-change: transform,background;
    background: #fff;
    border-bottom: 1px solid #ebeae8;
    transition: all .35s linear 0s;
    -o-transition: all .35s linear 0s;
    -moz-transition: all .35s linear 0s;
    -webkit-transition: all .35s linear 0s;
    will-change: transform;

    .header-top {
        background-color: #ebebeb;
        padding-top: 6px;
        padding-bottom: 6px;

        div.container {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: flex-end;

            ul {
                li:nth-child(1) {
                    /*display:none;*/
                }

                li {
                    display: inline-block;
                    margin-left: 15px;

                    a {
                        color: #5a2a82;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .header-bottom {
        position: relative;

        > div.container {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: space-between;
            height: 95px;

            @include breakpoint($md) {
                height: 75px;
            }

            div.logo {
                background-color: #5a2a82;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 212px;
                min-width: 200px;
                height: 130px;
                position: relative;
                top: -27px;
                left: 0;
                z-index: 15;

                @include breakpoint($md) {
                    top: 0;
                    height: 100%;

                    img {
                        width: 60%;
                    }
                }

                @include breakpoint($xs2) {
                    width: 180px;
                }

                @include breakpoint($xs) {
                    width: 160px;
                }

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            div.main-navigation {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: center;

                .search-content {
                    position: relative;
                    bottom: -25px;

                    @include breakpoint($md) {
                        bottom: unset;
                    }

                    .btn-search-trigger {
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        background: rgba(0, 0, 0, 0.05);
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.5);
                        -webkit-transition: all 0.1s linear;
                        -moz-transition: all 0.1s linear;
                        -o-transition: all 0.1s linear;
                        transition: all 0.1s linear;
                        cursor: pointer;
                        margin-left: 4px;

                        .fontello-icon {
                            display: inline-block;
                            font-family: $font-icons;
                            font-style: normal;
                            font-variant: normal;
                            font-weight: normal;
                            line-height: normal;
                            text-align: center;
                            text-decoration: inherit;
                            text-transform: none;

                            &::before {
                                content: '\e802';
                            }
                        }
                    }

                    .btn-search-trigger:hover {
                        background: rgba(0, 0, 0, 0.07);
                    }

                    .form-field {
                        background-color: #e5e5e5;
                        border: 0;
                        color: #010101;
                        height: 40px;
                        padding: 0 0 0 20px;
                        font-family: 'Open Sans', sans-serif;
                        position: absolute;
                        top: 0;
                        right: 40px;
                        z-index: 20;
                        width: 0;
                        opacity: 0;
                        -webkit-transition: all 0.2s linear;
                        -moz-transition: all 0.2s linear;
                        -o-transition: all 0.2s linear;
                        transition: all 0.2s linear;
                        will-change: width, opacity;
                    }

                    .search-button {
                        display: none;
                    }

                    &.open .form-field {
                        padding-right: 72px;
                        min-width: 260px;
                        opacity: 1;
                    }
                }

                > img.icon-black {
                    display: none;
                    cursor: pointer;
                    margin-right: 30px;

                    @include breakpoint($md) {
                        display: block;
                    }

                    @include breakpoint($xs) {
                        margin-right: 20px;
                    }
                }

                ul.header-menu {
                    height: 100%;
                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -ms-flex;
                    display: -o-flex;
                    display: flex;
                    align-items: center;

                    @include breakpoint($md) {
                        background: #eee;
                        height: calc(100vh - 100px);
                        margin: 0;
                        opacity: 0;
                        overflow: auto;
                        position: fixed;
                        right: 0;
                        top: 100px;
                        transform: translateX(-100%);
                        transition: all .3s ease 0s;
                        -o-transition: all .3s ease 0s;
                        -moz-transition: all .3s ease 0s;
                        -webkit-transition: all .3s ease 0s;
                        visibility: hidden;
                        width: 100%;
                        will-change: transform,opacity;
                        z-index: 9;
                        -webkit-flex-direction: column;
                        -moz-flex-direction: column;
                        -ms-flex-direction: column;
                        -o-flex-direction: column;
                        flex-direction: column;

                        &.is-active {
                            opacity: 1;
                            transform: translateX(0);
                            visibility: visible;
                        }
                    }

                    > li {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        text-transform: uppercase;
                        position: relative;

                        @include breakpoint($md) {
                            width: 100%;
                            height: 80px;
                            border-bottom: 1px solid $purple-font-color;
                        }

                        &.li-have-category {

                            @include breakpoint($md) {
                                position: relative;
                            }
                        }



                        > a {
                            color: #5a2a82;
                            font-size: em(15);
                            display: -webkit-flex;
                            display: -moz-flex;
                            display: -ms-flex;
                            display: -o-flex;
                            display: flex;
                            padding: 4px 12px;
                            padding-top: 0;
                            padding-bottom: 10px;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            transition: all 0.25s ease;
                            width: max-content;
                            
                            @include breakpoint($md2) {
                                font-size: em(14);
                                padding-left: 8px;
                                padding-right: 8px;
                            }

                            @include breakpoint($md) {
                                width: 100%;
                            }

                            img {
                                margin-left: 10px;
                            }

                            &:hover,
                            &.is-active {
                                background-color: #eee;
                            }
                        }

                        div.header-menu-dropdown {
                            position: absolute;
                            top: 100%;
                            right: 0;
                            min-width: 240px;
                            background: #fff;
                            opacity: 0;
                            visibility: hidden;
                            box-shadow: 0 1px 4px rgba(0,0,0,.1);
                            border-width: 1px 0;
                            border-style: solid;
                            border-color: #e6e6e6;
                            will-change: transform,opacity;
                            transform: translateY(-5%);
                            transition: all .3s ease 0s;
                            -o-transition: all .3s ease 0s;
                            -moz-transition: all .3s ease 0s;
                            -webkit-transition: all .3s ease 0s;
                            z-index: 10;
                            width: 100%;

                            @include breakpoint($md) {
                                width: 100%;
                            }

                            &.have-category {
                                width: 100%;

                                div.container {
                                    padding: 0;

                                    > ul {
                                        display: -webkit-flex;
                                        display: -moz-flex;
                                        display: -ms-flex;
                                        display: -o-flex;
                                        display: flex;
                                        flex-direction: column;
                                        position: relative;
                                        z-index: 15;
                                        border: 1px solid #e6e6e6;

                                        > li {
                                            display: flex;
                                            align-items: flex-start;
                                            width: 100%;
                                            margin-bottom: 0 !important;
                                            position: relative;

                                            &:last-child {
                                                margin-bottom: 0 !important;
                                            }

                                            > a {
                                                font-size: 15px;
                                                padding: 15px 20px 15px;
                                                flex: 1 1 auto;
                                            }

                                            > ul {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;
                                                border: 1px solid #e6e6e6;
                                                background: #fff;
                                                margin: 0;
                                                opacity: 0;
                                                overflow: auto;
                                                position: absolute;
                                                left: 100%;
                                                top: 0;
                                                transform: translateY(-100%);
                                                transition: all .3s ease 0s;
                                                -o-transition: all .3s ease 0s;
                                                -moz-transition: all .3s ease 0s;
                                                -webkit-transition: all .3s ease 0s;
                                                visibility: hidden;
                                                width: 100%;
                                                will-change: transform,opacity;
                                                z-index: 9;
                                                -webkit-flex-direction: column;
                                                -moz-flex-direction: column;
                                                -ms-flex-direction: column;
                                                -o-flex-direction: column;
                                                flex-direction: column;

                                                &.is-active {
                                                    opacity: 1;
                                                    transform: translateX(0);
                                                    visibility: visible;
                                                }

                                                @include breakpoint($md) {
                                                    left: 0;
                                                    top: 100%;
                                                }

                                                > li {
                                                    margin-bottom: 6px;
                                                    padding: 10px 15px;

                                                    @include breakpoint($sm) {
                                                        margin-bottom: 0px;
                                                        flex: 0 1 33%;
                                                        text-align: center;
                                                    }

                                                    &:last-child {
                                                        margin-bottom: 0;
                                                    }

                                                    a {
                                                        padding: 4px 12px;
                                                        transition: all 0.25s;

                                                        &:hover {
                                                            color: $purple-font-color;
                                                        }

                                                        @include breakpoint($md) {
                                                            font-size: em(14);
                                                            padding-left: 0px;
                                                            padding-right: 5px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.is-active {
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0);
                            }

                            > div.container {
                                padding: 30px 20px;

                                > ul {
                                    display: -webkit-flex;
                                    display: -moz-flex;
                                    display: -ms-flex;
                                    display: -o-flex;
                                    display: flex;
                                    align-items: flex-start;
                                    flex-direction: column;

                                    > li {
                                        display: flex;
                                        margin-bottom: 20px;

                                        a {
                                            transition: all 0.25s;

                                            &:hover {
                                                color: $purple-font-color;
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.header--not-top {
        transform: translateY(-30px);

        div.logo {
            height: 123px !important;

            @include breakpoint($md) {
                height: 76px !important;
            }

            img {
                transform: scale(0.8);
                margin-top: 25px;

                @include breakpoint($md) {
                    margin-top: 0;
                }
            }
        }

        div.main-navigation {

            ul.header-menu {

                @include breakpoint($sm) {
                    height: calc(100vh - 75px) !important;
                }
            }
        }
    }
}
