
section.novosti-page {
	margin-top: 60px;
	
	div.container {
		background-color: #fff;
		padding: 50px 35px;
        margin-bottom: 120px;

        @include breakpoint($md) {
            padding: 30px 15px;
            margin-bottom: 50px;
        }
		
		h3 {
			font-size: em(22);
			color: $orange-font-color;
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 26px;
		}

		> div.single-objava {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			padding: 25px;
			border: 1px solid #e1e1e1;
			margin-bottom: 30px;

            @include breakpoint($xs2) {
                flex-direction: column;
            }

            @include breakpoint($xs) {
                padding: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

			div.single-objava-image {
				flex: 0 1 200px;
				min-width: 200px;
				margin-right: 25px;

                @include breakpoint($xs2) {
                    margin-right: 0;
                    align-self: center;
                }
			}

			div.single-objava-text {

				h4 {
					font-size: em(22);
					font-weight: 700;
					color: $orange-font-color;
					margin-top: 7px;
					margin-bottom: 17px;

                    
				}
				p {
					font-size: em(16);
					margin-bottom: 17px;
					color: $gray-font-color;

                    @include breakpoint($xs) {
                        font-size: em(14);
                        text-align: justify;
                    }
				}
				a {
					text-transform: uppercase;
					color: $purple-font-color;
					font-weight: 700;
					font-size: em(12);
				}
			}
		}
	}
}