
section.single-novost-page {
	
	div.container {
		background-color: #fff;
		margin-top: 60px;
		margin-bottom: 120px;
		padding: 50px 60px;

        @include breakpoint($md) {
            padding: 30px;
        }

        @include breakpoint($sm) {
            padding: 20px 15px;
        }

		> p {
			font-size: em(14);
			color: #bcbcbc;
			margin-bottom: 20px;
			font-weight: 300;

			strong {				
				color: $gray-font-color;
			}
		}

		div.single-novost-top-text {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			margin-bottom: 67px;

            @include breakpoint($sm) {
                flex-direction: column;
            }

			> div:first-child {
				flex: 1 1 530px;
				min-width: 530px;
				margin-right: 28px;

                @include breakpoint($md) {
                    min-width: 400px;
                    flex: 1 1 auto;
                }

                @include breakpoint($sm) {
                    margin-right: 0;
                    min-width: unset;
                    margin-bottom: 25px;
                }
			}

			> div:last-child {
				flex: 1 1 100%;

				> p:first-child {
					font-size: em(16);
					color: $gray-font-color;
					font-weight: 600;
					margin-top: 20px;
					margin-bottom: 20px;
				}

				> h2 {
					color: $orange-font-color;
					font-size: em(40);
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 35px;

                    @include breakpoint($md) {
                        font-size: em(32);
                        line-height: 1.3;
                    }

                    @include breakpoint($sm) {
                        font-size: em(28);
                    }
				}

				> div {
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					justify-content: flex-start;
					margin-bottom: 50px;

					> p {
						margin-right: 15px;
						color: #585858;
					}
					> div {

						img {
							margin-right: 8px;
						}
					}
				}

				> p:last-child {
					font-size: em(16);
					color: $gray-font-color;
					font-weight: 600;
				}
			}
		}
		div.single-novost-main-text {

			p {
				font-size: em(16);
				color: $gray-font-color;
				margin-bottom: 25px;
			}
		}
	}
}