
section.single-product {
	padding-top: 60px;
	padding-bottom: 140px;

    @include breakpoint($sm) {
        padding-bottom: 100px;
    }
	
	> div.container {

		> p {
			color: #bcbcbc;
			font-size: em(14);
			margin-bottom: 12px;
		}

		div.single-product-info {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
            min-height: 176px;

            @include breakpoint($sm2) {
                margin-bottom: 30px;
            }

            @include breakpoint($xs) {
                flex-direction: column;
            }

			> div.single-product-image {
				background-color: #fff;
				border: 1px solid #e9e9e9;
				position: absolute;
				width: 420px;
                height: 500px;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: center;
				align-items: center;

                @include breakpoint($sm2) {
                    width: 380px;
                    height: 450px;
                    position: static;
                }	
                
                @include breakpoint($sm) {
                    height: 360px;
                    min-width: 300px;
                }

                @include breakpoint($xs2) {
                    min-width: 250px;
                }
                
                @include breakpoint($xs) {
                    align-self: center;
                    width: 100%;
                }
                
                > img {
                    max-width: 320px;
                    max-height: 100%;
                }			
			}

			> div.single-product-text {
				padding-left: 470px;
				padding-right: 50px;
				padding-top: 20px;
				padding-bottom: 20px;

                @include breakpoint($sm2) {
                    padding-left: 50px;
                }

                @include breakpoint($sm) {
                    padding-left: 30px;
                    padding-right: 20px;
                }

                @include breakpoint($xs2) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                @include breakpoint($xs) {
                    padding-left: 0px;
                    padding-right: 0px;
                }

				h3 {
					color: #ff974a;
					font-size: em(24);
					text-transform: uppercase;
					margin-top: 0;
					margin-bottom: 12px;

                    @include breakpoint($xs2) {
                        font-size: em(20);
                    }
				}
				p:first-of-type {
					color: #808080;
					font-size: em(18);
					font-weight: bold;
					margin-bottom: 30px;
					line-height: 1;

                    @include breakpoint($xs2) {
                        font-size: em(14);
                    }
				}
				p:last-of-type {
					color: #808080;
					font-size: em(14);
				}

                ul {
                    padding-left: 25px;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    li {
                        margin-bottom: 6px;
                    }
                }
			}
		}
	}
	div.container-fluid {
		background-color: #94c347;
        min-height: 246px;

		div.additional-text {
			padding-left: 470px;
			padding-right: 50px;
			padding-top: 40px;
			padding-bottom: 40px;

            @include breakpoint($sm2) {
                padding-left: 0;
            }

			p:first-child {
				color: #fff;
				font-size: em(18);
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 25px;

                @include breakpoint($sm) {
                    font-size: em(14);                   
                }
			}

            p {
                text-transform: none;
                font-weight: 400;
                color: #fff;
				font-size: em(18);
                margin-bottom: 10px;
            }

			ul {
				list-style-image: url('../img/arrow-right.png');
				padding-left: 20px;

				li {
					color: #fff;
				    font-size: em(18);
				    margin-bottom: 12px !important;
				    padding-left: 5px;

                    @include breakpoint($sm) {
                        font-size: em(14);
                    }
				} 
			}

            div {
                color: #fff;
				font-size: em(18);
				margin-bottom: 12px !important;
				padding-left: 5px;

                @include breakpoint($sm) {
                    font-size: em(14);
                }
            }
		}
	}
}