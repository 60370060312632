//########################################
//## FONT VARIABLES ######################
//########################################

$font-main: MavenPro-Regular;
$font-main-700: MavenPro-Bold;
$font-main-700-B: MavenPro-Black;
$font-icons:mrdoor-icons;

//########################################
//## SIZES ###############################
//########################################

$browser-context: 14; // px
$browser-line-height: 14; // px