
section.product-koncentrati-info {
	padding-bottom: 100px;

    @include breakpoint($sm) {
        padding-bottom: 50px;
    }

	div.container {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
        margin-bottom: 30px;

        @include breakpoint($md) {
            flex-direction: column;
        }

		div.sastav {
			flex: 0 1 350px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
            border: 1px solid #e9e9e9;
            

            @include breakpoint($md) {
                flex: 0 1 100%;
                margin-bottom: 30px;
            }

            &:first-child {

                > div {

                    > h4 {
                        border-right: 1px solid #fff;
                    }
                }
            }

            &:nth-child(2) {
                margin-right: auto;

                > div {

                    > div {

                        h2 {
                            border-right: none !important;
                        }                        
                    }
                }
            }

			> div {
				flex: 1 1 50%;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				-webkit-flex-direction: column;
				-moz-flex-direction: column;
				-ms-flex-direction: column;
				-o-flex-direction: column;
				flex-direction: column;
				

				> h4 {
					color: #5a2a82;
                    background-color: #e9e9e9;
					font-size: em(16);
                    font-weight: 600;
                    line-height: 1;
					text-transform: uppercase;
					padding: 14px 20px;
					margin-bottom: 0;
				}

				&:first-child {
					
                    > div {

                        h2 {
                            border-right: 1px solid #fff;
                        }
                    }					
				}

				&:last-child {
					border-left: none;
				}

				> div {
					background-color: #fff;					
					flex-grow: 1;

                    h2 {
                        color: #5a2a82;
                        background-color: #e9e9e9;
					    font-size: em(16);
                        font-weight: 600;
                        line-height: 1;
					    text-transform: uppercase;
					    padding: 14px 20px;
					    margin-bottom: 10px;
                    }

                    p {
                        padding: 0 20px;
                        margin-bottom: 10px;
                    }

					> ul {
						list-style-type: disc;
						padding:0 35px 15px;

						li {
							color: #808080;
							font-size: em(14);
							line-height: 1.7;
						}
					}
				}
			}
		}

		div.tabela {
			width: 100%;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			-webkit-flex-direction: column;
			-moz-flex-direction: column;
			-ms-flex-direction: column;
			-o-flex-direction: column;
			flex-direction: column;
            margin-left: 30px;

            @include breakpoint($md) {
                margin-left: 0;
                margin-bottom: 30px;
            }

            @include breakpoint($sm) {
                font-size: em(12);
            }

            > p {
                display: none;
            }

            table {

                tbody {

                    tr:first-child {
                        background-color: #5a2a82;

                        td {
                            color: #fff;
                            font-size: em(16);
                        }                       
                    }

                    tr {
                        background-color: #fff;

                        &:nth-child(even) {
                            background-color: #e9e9e9;
                        }
                     }

                    td {
                        padding:14px 20px; 
                         color: #808080;
                        
                         @include breakpoint($sm) {
                            font-size: em(12);
                            padding:8px 12px; 
                        }

                        @include breakpoint($xs) {
                            padding:4px 4px; 
                            font-size: em(11);
                        }
                    }
                }
            }

			div.table-head {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				background-color: #5a2a82;
				
				> div {

					> p {
						padding: 14px 20px;
						font-size: em(16);
						color: #fff;

                        @include breakpoint($sm) {
                            font-size: em(14);
                            padding:8px 12px; 
                        }

                        @include breakpoint($xs) {
                            padding:4px 4px;
                            font-size: em(11); 
                        }
					}
				}

				> div:first-child {
					flex: 1 1 340px;
					border-right: 2px solid #fff;

					
				}
				> div:nth-child(2) {
					flex: 1 1 194px;
					border-right: 2px solid #fff;
				}
				> div:nth-child(3) {
					flex: 1 1 194px;
				}
			}
			div.table-body {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				-webkit-flex-direction: column;
				-moz-flex-direction: column;
				-ms-flex-direction: column;
				-o-flex-direction: column;
				flex-direction: column;
				border-right: 1px solid #e9e9e9;
				border-left: 1px solid #e9e9e9;
				border-bottom: 1px solid #e9e9e9;

				> div.table-body-row {
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					background-color: #fff;

					&:nth-child(even) {
						background-color: #e9e9e9;
					}

					> div {

						> p {
							color: #808080;
							font-size: em(14);

                            @include breakpoint($sm) {
                                font-size: em(12);
                            }
						}
					}

					> div:first-child {
						flex: 1 1 340px;
						border-right: 2px solid #e9e9e9;

						> p {
							padding: 14px 20px;

                            @include breakpoint($sm) {
                                font-size: em(12);
                                padding:8px 12px; 
                            }

                            @include breakpoint($xs) {
                                padding:4px 4px; 
                                font-size: em(11);
                            }
						}
					}
					> div:nth-child(2) {
						flex: 1 1 194px;
						display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;
						border-right: 2px solid #e9e9e9;

						> div {
							flex: 1 1 50%;
							display: -webkit-flex;
							display: -moz-flex;
							display: -ms-flex;
							display: -o-flex;
							display: flex;
							justify-content: center;
							align-items: center;

							&:first-child {
								border-right: 1px solid #e9e9e9;
							}
						}

					}
					> div:nth-child(3) {
						flex: 1 1 194px;
						display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;

						> div {
							flex: 1 1 50%;
							display: -webkit-flex;
							display: -moz-flex;
							display: -ms-flex;
							display: -o-flex;
							display: flex;
							justify-content: center;
							align-items: center;

							&:first-child {
								border-right: 1px solid #e9e9e9;
							}
						}
					}
				}
			}
		}
	}
}