
section.kontakt-page {

    div.container-fluid {
        position: relative;
        margin-bottom: 70px;

        section.stores {
            position: relative;

            div.inner {
                z-index: 10;
                position: relative;
                background-color: #fff;
                border: 1px solid #dcdcdc;
                padding: 30px 50px;
                width: 500px;
                margin-top: 80px;
                right: 15px;

                > h3 {
                    color: #94d60a;
                    text-transform: uppercase;
                    font-size: em(22);
                    margin-bottom: 35px;
                }

                div.stores__left-side {

                    div.location-search {
                        display: none;

                        h4 {
                            font-size: em(18);
                        }

                        input {
                            border: 1px solid #d7d7d7;
                            height: 50px;
                            width: 300px;
                            padding-left: 20px;

                            &::placeholder {
                                color: #bababa;
                            }
                        }
                    }

                    ul.store-list {

                        li.no-stores {
                            margin-bottom: 30px;
                        }

                        li.store {
                            cursor: pointer;

                            div.title {
                                font-size: em(23);
                                color: $purple-font-color;
                                font-weight: bold;
                                margin-bottom: 15px;
                            }

                            div.address {
                                color: $gray-font-color;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }

            div.map {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 600px;

                div#map-canvas {
                }
            }
        }



        div#map {
            width: 100%;
            height: 600px;

            @include breakpoint($extra-lg) {
                height: 450px;
            }
        }

        > div.container {

            @include breakpoint($sm) {
                padding-left: 0;
                padding-right: 0;
            }

            div.mesta {
                position: absolute;
                top: 10%;
                left: 18.5%;
                background-color: #fff;
                border: 1px solid #dcdcdc;
                padding: 15px 10px 15px 20px;

                @include breakpoint($md2) {
                    left: 5%;
                }

                @include breakpoint($sm) {
                    position: static;
                    border-right: none;
                    border-left: none;
                }

                > h3 {
                    color: #94d60a;
                    text-transform: uppercase;
                    font-size: em(22);
                    margin-bottom: 45px;

                    @include breakpoint($extra-lg) {
                        margin-bottom: 15px;
                        font-size: em(20);
                    }
                }

                > div {
                    overflow-y: auto;
                    max-height: 370px;
                    padding-right: 20px;

                    @include breakpoint($extra-lg) {
                        max-height: 300px;
                    }

                    @include breakpoint($lg2) {
                        max-height: 260px;
                    }

                    @include breakpoint($sm) {
                        max-height: unset;
                        overflow-y: unset;
                    }

                    > div {
                        position: relative;
                        margin-bottom: 30px;

                        @include breakpoint($extra-lg) {
                            margin-bottom: 15px;
                        }
                        /*> a {
                            position: absolute;
                            top:0;
                            left: 0;
                            right:0;
                            bottom: 0;
                        }*/
                        h4 {
                            font-size: em(16);
                            color: $purple-font-color;
                            font-weight: bold;
                            margin-bottom: 15px;

                            @include breakpoint($extra-lg) {
                                font-size: em(18);
                                margin-bottom: 10px;
                            }
                        }

                        > p {
                            color: $gray-font-color;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 300;

                            @include breakpoint($extra-lg) {
                                font-size: em(12);
                            }

                            a {
                                transition: all 0.15s;
                                font-weight: 500;

                                &:hover {
                                    color: $purple-font-color;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    > div.container {
        background-color: #e9e9e9;
        margin-bottom: 150px;
        padding: 50px 80px;
        margin-top: 100px;

        .btn--disabled {
            pointer-events: none;
            background-color: #ccc !important;
        }

        @include breakpoint($sm) {
            padding: 40px;
            margin-bottom: 50px;
        }

        @include breakpoint($xs) {
            padding: 20px;
        }

        > h3 {
            color: $purple-font-color;
            font-size: em(22);
            font-weight: 500;
            margin-bottom: 55px;
        }

        > div {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            justify-content: space-between;

            @include breakpoint($md) {
                flex-direction: column;
            }

            > div {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                -webkit-flex-direction: column;
                -moz-flex-direction: column;
                -ms-flex-direction: column;
                -o-flex-direction: column;
                flex-direction: column;
                flex: 1 1 47%;

                &:first-child {
                    max-width: 380px;
                    margin-right: 70px;

                    @include breakpoint($md) {
                        margin-right: 0;
                        margin-bottom: 50px;
                        max-width: unset;
                    }
                }

                input {
                    border: 1px solid #d7d7d7;
                    height: 50px;
                    margin-bottom: 25px;
                    padding-left: 20px;

                    &::placeholder {
                        color: #bababa;
                    }
                }

                select {
                    height: 50px;
                    border: 1px solid #d7d7d7;
                    background-image: url('../img/001-arrow-point-to-right-copy-3.png');
                    background-position: 95% 50%;
                    background-repeat: no-repeat;

                    &::placeholder {
                        color: #bababa;
                    }
                }

                &:nth-child(2) {

                    textarea {
                        border: 1px solid #d7d7d7;
                        padding: 18px;

                        &::placeholder {
                            color: #bababa;
                        }
                    }

                    a {
                        align-self: flex-end;
                        margin-top: 25px;
                        width: 190px;
                        height: 50px;
                        line-height: 50px;
                        text-transform: uppercase;
                        font-size: em(12);
                    }
                }
            }
        }
    }
}

.gm-style-iw {
    max-width: 350px;

    @include breakpoint($sm) {
        max-width: 250px;
    }

    h4 {
        font-size: em(24px);

        @include breakpoint($sm) {
            font-size: em(24px);
        }
    }
}
