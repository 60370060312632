/*@import "compass/css3/box-sizing";
@import "compass/css3/images";
@import "compass/css3/user-interface";*/

$chosen-sprite: image-url('chosen-sprite.png') !default;
$chosen-sprite-retina: image-url('chosen-sprite@2x.png') !default;

/* @group Base */
.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    float: right;

    &.chosen-container-active {
        &:before {
            width: 100%;
        }
    }

    .chosen-drop {
        left: 0;
        margin-top: 3px;
        opacity: 0;
        z-index: 3;
        width: 100%;
        display: block;
        margin-top: 8px;
        position: absolute;
        visibility: hidden;
        letter-spacing: 2.5px;
        font-size: 17px;
        transition: all 0.3s ease;
        border: 1px solid #e9e9e9;
        background-color: #fff;
    }

    &.chosen-with-drop .chosen-drop {
        opacity: 1;
        visibility: visible;
        transform: translateY(7px);
    }

    a {
        cursor: pointer;
    }

    .search-choice, .chosen-single {
        .group-name {
            margin-right: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: normal;
            color: #999999;

            &:after {
                content: ":";
                padding-left: 2px;
                vertical-align: top;
            }
        }
    }
}
/* @end */

/* @group Single Chosen */
.chosen-container-single {
    width: auto !important;

    .chosen-single {
        z-index: 5;
        width: auto !important;
        display: block;
        cursor: pointer;
        position: relative;
        padding: 0;
        transition: all .2s ease-in-out 0s;
        -o-transition: all .2s ease-in-out 0s;
        -moz-transition: all .2s ease-in-out 0s;
        -webkit-transition: all .2s ease-in-out 0s;
        text-decoration: none;
        background-color: #fff;
        font-size: em(14);
    }

    .chosen-default {
        color: #999;
    }

    .chosen-single span {
        display: block;
        overflow: hidden;
        margin-right: 0;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 1px;
        line-height: 14px;
        color: #4b4949 !important;
        transition: all .1s ease 0s;
        -o-transition: all .1s ease 0s;
        -moz-transition: all 1s ease 0s;
        -webkit-transition: all .1s ease 0s;
        padding: 0 25px 0 0 !important;
    }

    .chosen-single-with-deselect span {
        margin-right: 38px;
    }

    .chosen-single abbr {
        position: absolute;
        top: 6px;
        right: 26px;
        display: block;
        width: 12px;
        height: 12px;
        background: $chosen-sprite -42px 1px no-repeat;
        font-size: 1px;

        &:hover {
            background-position: -42px -10px;
        }
    }

    &.chosen-disabled .chosen-single abbr:hover {
        background-position: -42px -10px;
    }

    .chosen-single div {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 18px;
        height: 100%;

        b {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 4.5px 0 4.5px;
            border-color: #848484 transparent transparent transparent;
            position: absolute;
            bottom: 5px;
            @include transition(all,0.2s,0s,1);
        }
    }

    .chosen-search {
        position: relative;
        z-index: 1010;
        margin: 0;
        padding: 3px 4px;
        white-space: nowrap;

        input[type="text"] {
            margin: 1px 0;
            padding: 4px 20px 4px 5px;
            width: 100%;
            height: auto;
            outline: 0;
            border: 1px solid #aaa;
            background: #fff $chosen-sprite no-repeat 100% -20px;
            /*@include background($chosen-sprite no-repeat 100% -20px);*/
            font-size: 1em;
            font-family: sans-serif;
            line-height: normal;
            border-radius: 0;
        }
    }

    .chosen-drop {
        top: 100%;
        border-top: 2px solid $main-color;
        border-radius: 0;
        background-clip: padding-box;
    }

    &.chosen-container-single-nosearch .chosen-search {
        position: absolute;
        left: -9999px;
    }
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
    color: #000;
    font-size: 19px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    max-height: 280px;
    -webkit-overflow-scrolling: touch;

    li {
        display: none;
        margin: 0;
        font-size: 14px;
        padding: 18px 20px;
        list-style: none;
        word-wrap: break-word;
        -webkit-touch-callout: none;
        border-bottom: 1px solid #e9e9e9;
        transition: all 0.3s ease;

        &:last-child {
            border: 0;
        }

        &.active-result {
            display: list-item;
            cursor: pointer;
        }

        &.disabled-result {
            display: list-item;
            color: #ccc;
            cursor: default;
            display: none !important;
        }

        &.highlighted {
            background-color: #fafafa;
            /*@include background-image(linear-gradient(#3875d7 20%, #2a62bc 90%));*/
            color: #e60028;
        }

        &.no-results {
            color: #777;
            display: list-item;
            background: #f4f4f4;
        }

        &.group-result {
            display: list-item;
            font-weight: bold;
            cursor: default;
        }

        &.group-option {
            padding-left: 15px;
        }

        em {
            font-style: normal;
            text-decoration: underline;
        }
    }
}
/* @end */

/* @group Multi Chosen */
.chosen-container-multi{
  .chosen-choices {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    height: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    /*@include background-image(linear-gradient(#eee 1%, #fff 15%));*/
    cursor: text;
  }
  .chosen-choices li {
    float: left;
    list-style: none;
    &.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      input[type="text"] {
        margin: 1px 0;
        padding: 0;
        height: 25px;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        box-shadow: none;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border-radius: 0;
      }
    }
    &.search-choice {
      position: relative;
      margin: 3px 5px 3px 0;
      padding: 3px 20px 3px 5px;
      border: 1px solid #aaa;
      max-width: 100%;
      border-radius: 3px;
      background-color: #eeeeee;
      /*@include background-image(linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%));*/
      background-size: 100% 19px;
      background-repeat: repeat-x;
      background-clip: padding-box;
      box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(#000,.05);
      color: #333;
      line-height: 13px;
      cursor: default;
      span {
        word-wrap: break-word;
      }
      .search-choice-close {
        position: absolute;
        top: 4px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;
        background: $chosen-sprite -42px 1px no-repeat;
        font-size: 1px;
        &:hover {
          background-position: -42px -10px;
        }
      }
    }
    &.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      /*@include background-image(linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%));*/
      color: #666;
    }
    &.search-choice-focus {
      background: #d4d4d4;
      .search-choice-close {
        background-position: -42px -10px;
      }
    }
  }
  .chosen-results {
    margin: 0;
    padding: 0;
  }
  .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
  }
}
/* @end */

/* @group Active  */
.chosen-container-active {
    .chosen-single {
    }

    &.chosen-with-drop {
        .chosen-single {
            div {
                b {
                    transform: rotate(-180deg);
                }
            }
        }

        .chosen-single div {
            border-left: none;
            background: transparent;
        }
    }

    .chosen-choices {
        border: 1px solid #5897fb;
        box-shadow: 0 0 5px rgba(#000,.3);

        li.search-field input[type="text"] {
            color: #222 !important;
        }
    }
}
/* @end */

/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
  .chosen-single {
    cursor: default;
  }
  .chosen-choices .search-choice .search-choice-close {
    cursor: default;
  }
}
/* @end */

/* @group Right to Left */
.chosen-rtl {
  text-align: right;
  .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0;
  }
  .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl;
  }
  .chosen-single-with-deselect span {
    margin-left: 38px;
  }
  .chosen-single div {
    right: auto;
    left: 3px;
  }
  .chosen-single abbr {
    right: auto;
    left: 26px;
  }
  .chosen-choices li {
    float: right;
    &.search-field input[type="text"] {
      direction: rtl;
    }
    &.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px;
      .search-choice-close {
        right: auto;
        left: 4px;
      }
    }
  }
  &.chosen-container-single-nosearch .chosen-search,
  .chosen-drop {
    left: 9999px;
  }
  &.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0;
  }
  .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0;
  }
  &.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
  }
  .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: #fff $chosen-sprite no-repeat -30px -20px;
    /*@include background($chosen-sprite no-repeat -30px -20px);*/
    direction: rtl;
  }
  &.chosen-container-single{
    .chosen-single div b {
      
    }
    &.chosen-with-drop{
      .chosen-single div b {
        
      }
    }
  }
}

/* @end */

/* @group Retina compatibility */
/*@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: $chosen-sprite-retina !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
}*/
/* @end */
