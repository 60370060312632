
section.galerija {
	padding-top: 60px;
	padding-bottom: 150px;

    @include breakpoint($lg2) {
        padding-bottom: 100px;
    }

    @include breakpoint($xs) {
        padding-bottom: 50px;
    }
	
	div.container {
		background-color: #fff;
		padding: 50px 30px;

		h3 {
			color: $orange-font-color;
			font-size: em(22);
			text-transform: uppercase;
			margin-bottom: 65px;
		}

		> div {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			div.gallery-group {
				position: relative;
				overflow: hidden;
				flex-basis: calc(33% - 30px);
				margin-bottom: 30px;
				margin-left: 15px;
				margin-right: 15px;	
                max-height: 300px;

                @include breakpoint($sm) {
                    flex-basis: calc(50% - 30px);
                }

                @include breakpoint($xs2) {
                    flex-basis: calc(100% - 30px);
                    max-height: unset;
                }

                div.lightgallery {
                    max-height: 300px;

                    @include breakpoint($xs2) {
                        max-height: unset;
                    }

                    a {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 300px;
                        display: block;

                        @include breakpoint($md) {
                            height: 260px;
                        }

                        @include breakpoint($sm2) {
                            height: 240px;
                        }

                        @include breakpoint($sm) {
                            height: 300px;
                        }

                        @include breakpoint($xs2) {
                            height: 400px;
                        }

                        @include breakpoint($xs) {
                            height: 300px;
                        }

                        img {
                            max-height: 300px;
                            width: 100%;
                            height: auto;
                            display: none;
                        }
                    }
                }

				div.content {
					  background-color: #5a2a82;
					  bottom: -100%;
					  color: #fff;
					  height: 100%;
					  left: 0%;
					  position: absolute;
					  transition: bottom 0.5s ease;
					  width: 100%;
					  padding: 16px 20px;

                      @include breakpoint($md) {
                          bottom: -60%;
                      }

                      @include breakpoint($sm) {
                          bottom: -75%;
                      }

					  p {
					  	font-size: em(11);
					  	margin-bottom: 12px;
					  }
					  h4 {
					  	font-size: em(16);
					  	margin-bottom: 0;
					  	margin-top: 0;
					  }
				}

				&:hover {

					div.content {
						bottom: -60%;

                        @include breakpoint($sm) {
                            bottom: -75%;
                        }
					}
				}
			}			
		}
	}
}

.lg-outer {

	div.lg-thumb-outer {
		height: 150px !important;
		padding-bottom: 25px;
		background-color: transparent;
		border-top: 1px solid #b0b0b0;
		
		.lg-thumb {
			display: flex;
		    justify-content: center;
		    width: unset !important;

		    > div.lg-thumb-item {
		    	width: 185px !important;
		    	height: 115px !important;

		    	&.active,
		    	&:hover {
		    		border-color: #5a2a82;
		    		border-width: 3px;
		    	}
		    }
	    } 

	}	

	.lg-inner {

		.close-gallery {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: transparent;
		}
	}
}

.lg-backdrop {
	background-color: rgba(0,0,0,0.72);
}