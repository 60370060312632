
section.otkupna-cena {
	padding-top: 100px;

    @include breakpoint($sm) {
        padding-top: 50px;
    }
	
	> div.container {

		> div:first-child {
          background-color: #94c347;
          color: #fff;
          padding: 45px;
          margin-bottom: 50px;

          @include breakpoint($sm) {
              padding: 20px;
          }

            h3 {
              font-size: em(22);
              text-transform: uppercase;
            }

            > div {
              display: -webkit-flex;
              display: -moz-flex;
              display: -ms-flex;
              display: -o-flex;
              display: flex;

              p {
                font-size: em(14);
                padding-right: 20px;
              }

              ul {

                  li {
                      margin-bottom: 10px;
                      line-height: 1.2;
                  }
              }
            }
        }
	}
	div.container-fluid {
		background-color: #e9e9e9;
		margin-bottom: 50px;

		div.container {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			padding-top: 80px;
			padding-bottom: 120px;

            @include breakpoint($sm) {
                flex-direction: column;
            }

			div.mesta {
				min-width: 230px;

                @include breakpoint($sm) {
                    order: 2;
                }

				h3 {
					color: #94d60a;
					font-size: em(22);
					text-transform: uppercase;
					margin-bottom: 60px;

                    @include breakpoint($sm2) {
                        margin-bottom: 30px;
                    }
				}
				> div {
					margin-bottom: 40px;
                    cursor: pointer;

					&:last-child {
						margin-bottom: 0;
					}

					h4 {
						color: #5a2a82;
						font-size: em(23);
                        font-weight: 700;

                        @include breakpoint($xs2) {
                            font-size: em(20);
                        }
					}
					p {
						color: #808080;
						font-size: em(14);
					}
				}
			}

			div#map {
				height: 500px;
				width: 100%;				
				margin-left: 230px;

                @include breakpoint($md) {
                    margin-left: 150px;
                }

                @include breakpoint($sm2) {
                    margin-left: 50px;
                }

                @include breakpoint($sm) {
                    order: 1;
                    margin-left: 0;
                    margin-bottom: 50px;
                }
			}
		}
	}
}