
section.zaposleni {
	margin-top: 60px;

	> div.container {

		div.zaposleni-main-image {
			margin-bottom: 30px;
			
			img {
				max-width: 100%;
				height: auto;
				display: block;
			}
		}

		div.nas-tim {
			background-color: #fff;
			padding: 50px 35px;
			margin-bottom: 30px;

            @include breakpoint($sm) {
                padding: 30px 15px;
            }

			h3 {
				color: $orange-font-color;
				font-size: em(22);
				text-transform: uppercase;
				margin-bottom: 30px;
				margin-top: 0;
			}

			> div {

				> p {
					-webkit-column-count: 1; /* Chrome, Safari, Opera */
                    -moz-column-count: 1; /* Firefox */
                    column-count: 1;
                    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
                    -moz-column-gap: 40px; /* Firefox */
                    column-gap: 40px;
					font-size: em(14);
					color: $gray-font-color;
                    width: 50%;

                    @include breakpoint($sm) {
                        width: 100%;
                    }
				}
			}
		}

		/*> div:nth-child(3) {
			background-color: #94c347;
			padding: 50px;
			margin-bottom: 60px;

			> h3 {
				font-size: em(30);
				font-weight: 300;
				color: #fff;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 90px;
			}

			> div {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				> div {
					flex: 0 1 25%;
					text-align: center;
					margin-bottom: 70px;

					> div.zaposleni-image {
						background-color: #fff;
						margin-left: 30px;
						margin-right: 30px;
						height: 215px;
						width: 215px;
						-webkit-clip-path: circle(50% at 50% 50%);
                        clip-path: circle(50% at 50% 50%);
                        display: -webkit-flex;
                        display: -moz-flex;
                        display: -ms-flex;
                        display: -o-flex;
                        display: flex;
                        -webkit-flex-direction: column;
                        -moz-flex-direction: column;
                        -ms-flex-direction: column;
                        -o-flex-direction: column;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;

                        img {
                        	max-width: 100%;
                        	height: auto;
                        	display: block;
                        }
					}

					h4 {
						font-size: em(16);
						color: #fff;
						text-transform: uppercase;
						font-weight: 900;
						line-height: 20px;
						margin-bottom: 0;
						margin-top: 14px;
					}

					p {
						font-size: em(16);
						color: #fff;
						text-transform: uppercase;
						font-weight: 400;
					}
				}
			}
		}*/

        div.program {
            background-color: #fff;
			padding: 50px 35px;
			margin-bottom: 30px;
            display: flex;
            justify-content: space-between;

            @include breakpoint($sm) {
                padding: 30px 15px;
                flex-direction: column;
            }

            > div:first-child {
                max-width: 70%;
                padding-right: 15px;

                @include breakpoint($sm) {
                    margin-bottom: 40px;
                    text-align: center;
                    max-width: 100%;
                    padding-right: 0;
                }

                h3 {
				    color: $orange-font-color;
				    font-size: em(22);
				    text-transform: uppercase;
				    margin-bottom: 30px;
				    margin-top: 0;
			    }

                p {
                    font-size: em(14);
			        color: $gray-font-color;
                    margin-bottom: 6px;

                    @include breakpoint($sm) {
                        text-align: justify;
                    }
                }
            }
			
			> div:last-child {

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img:last-child {
                        position: absolute;
                    }
                }

                .mfp-fade.mfp-bg {
	                opacity: 0;
	                -webkit-transition: all 0.15s ease-out; 
	                -moz-transition: all 0.15s ease-out; 
	                transition: all 0.15s ease-out;
                }
                .mfp-fade.mfp-bg.mfp-ready {
	                opacity: 0.8;
                }
                .mfp-fade.mfp-bg.mfp-removing {
	                opacity: 0;
                }

                .mfp-fade.mfp-wrap .mfp-content {
	                opacity: 0;
	                -webkit-transition: all 0.15s ease-out; 
	                -moz-transition: all 0.15s ease-out; 
	                transition: all 0.15s ease-out;
                }
                .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	                opacity: 1;
                }
                .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	                opacity: 0;
                }
            }
        }

        div.oglasi {
            background-color: #fff;
			padding: 50px 35px;
			margin-bottom: 30px;
            margin-top: 30px;

            @include breakpoint($sm) {
                padding: 30px 15px;
            }

			p.title {
				font-size: em(24);
				text-transform: uppercase;
				margin-bottom: 15px;
				font-weight: 300;
                line-height:1;
                color: #5a2a82;
			}

			ul {
                list-style-type: disc;
                padding-left: 40px;
                margin-bottom: 20px;

                li {
                    font-size: em(17);
                    margin-bottom: 6px;
                    color: #5a2a82;

                    a {
                        color: #5a2a82;
                        transition: all 0.25s;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
	}

	div.container-fluid {
		background-color: #e9e9e9;
		padding-top: 40px;

		div.postani-deo-tima {

			div.container {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: space-between;

                @include breakpoint($md) {
                    flex-direction: column;
                    align-items: center;
                }
				
				> div {
					flex: 0 1 47%;
                    align-self: flex-end;

                    @include breakpoint($md) {
                        align-self: center;
                    }

					&:first-child {
						align-self: center;
						color: $purple-font-color;

                        p:nth-child(1) {
							font-size: em(24);
							text-transform: uppercase;
							margin-bottom: 15px;
							font-weight: 300;
                            line-height:1;
						}
						p:nth-child(2) {
							font-size: em(17);
							margin-bottom: 25px;
							font-weight: 300;
                            line-height: 1.3;
						}
						p:nth-child(3) {
							font-size: em(17);
							margin-bottom: 50px;
							font-weight: 300;

							a {
								font-weight: 700;
								color: $purple-font-color;
							}
						}

                        ul {
                            list-style-type: disc;
                            padding-left: 40px;
                            margin-bottom: 20px;

                            li {
                                font-size: em(17);
                                margin-bottom: 6px;
                                color: #5a2a82;

                                a {
                                    color: #5a2a82;
                                    transition: all 0.25s;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
					}
				}
			}
		}
	}
}