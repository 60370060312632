.btn {
    background-color: $main-color;
    color: $white;
    text-align: center;
    font-size: em(14);
    @include transition(all,0.3s,0s,2);
    cursor: pointer;
    display: inline-block;
    letter-spacing:1px;
    -webkit-appearance: button-bevel;

    &:hover {
        background-color: $main-color-85;
        color: white;
    }



    &--purple {
        background-color: #5a2a82;
        border: 1px solid #5a2a82;
    }
    &--green {
        background-color: #94c347;
        border: 1px solid #94c347;
    }
}
